import { PATH_NAME } from 'configs';
import { GuestRouteGuard, RolePermissionRouteGuard } from 'guards';
import concat from 'lodash/concat';
import { FC, lazy } from 'react';

import { Role } from 'models/user';

import { InternalPermission, PartnerPermission, SystemPermission } from 'types';

import { RedirectFirstPage } from 'components/organisms/NavMenu/RedirectFirstPage';

import Error404 from 'pages/Error404';
import { userPermission } from 'pro/config/user';

export interface RouteConfig {
  path: string;
  roles?: Role[];
  permissions?: boolean
  element?: FC; // this value should be undefined when roleComponents is exist
  roleComponents?: { [key in Role]?: FC }; // using for render different role with same path
  title?: string;
  breakLineTitle?: [string, string];
  metaData?: { title: string };
  guard?: FC<any>;
  layout?: FC;
  disabled?: boolean;
  type?: 'all' | 'internal' | 'partner' | 'superHq';
  defaultScrollOnTop?: boolean;
  excludeMainLayout?: boolean;
}

const Demo = lazy(() => {
  if (process.env.NODE_ENV === 'development') {
    return import('pages/Demo');
  }
  return import('pages/Error404');
});

// const HqAdminDashboard = lazy(() => import('pages/Dashboard/HqAdminView'));
// const InternalAdminDashboard = lazy(() => import('pages/Dashboard/InternalAdminView'));
// const AreaAdminViewDashboard = lazy(() => import('pages/Dashboard/AreaAdminView'));
// const LocationAdminViewDashboard = lazy(() => import('pages/Dashboard/LocationAdminView'));
const Login = lazy(() => import('pages/Login'));
// const CompanyNew = lazy(() => import('pages/CompanyNew'));
// const CompanyEdit = lazy(() => import('pages/CompanyEdit'));
// const JobTypeEdit = lazy(() => import('pages/JobTypeEdit'));
// const JobTypeNew = lazy(() => import('pages/JobTypeNew'));
// const JobTypeList = lazy(() => import('pages/JobType'));
// const HqUserEdit = lazy(() => import('pages/HQUserEdit'));
// const HqUserNew = lazy(() => import('pages/HQUserNew'));
// const HqUser = lazy(() => import('pages/HQUser'));
// const EducationalInstitute = lazy(() => import('pages/EducationalInstitute'));
// const EducationalInstituteNew = lazy(() => import('pages/EducationalInstituteNew'));
// const EducationalInstituteEdit = lazy(() => import('pages/EducationalInstituteEdit'));
const SystemLocations = lazy(() => import('pages/SystemLocations'));
const Applicant = lazy(() => import('pages/Applicant'));
const ApplicantNew = lazy(() => import('pages/ApplicantNew'));
const ApplicantEdit = lazy(() => import('pages/ApplicantEdit'));
const BillingRecord = lazy(() => import('pages/BillingRecord'));
// const CompanyAssignCredit = lazy(() => import('pages/CompanyAssignCredit'));
// const CompanyCreditHistory = lazy(() => import('pages/CompanyCreditHistory'));
// const CompanyDebitCreditHistory = lazy(() => import('pages/CompanyDebitCreditHistory'));
// const JodCreditInCompany = lazy(() => import('pages/JodCreditInCompany'));
const PaymentSummary = lazy(() => import('pages/PaymentSummary'));
const PaymentProcessed = lazy(() => import('pages/PaymentProcessed'));
const PaymentFuture = lazy(() => import('pages/PaymentFuture'));
const ReportList = lazy(() => import('pages/Report'));
// const JobTemplateList = lazy(() => import('pages/JobTemplate'));
// const JobTemplateNew = lazy(() => import('pages/JobTemplateNew'));
// const JobTemplateEdit = lazy(() => import('pages/JobTemplateEdit'));
// const ManagerList = lazy(() => import('pages/Manager'));
// const ManagerNew = lazy(() => import('pages/ManagerNew'));
// const ManagerEdit = lazy(() => import('pages/ManagerEdit'));
const SystemManager = lazy(() => import('pages/SystemManager'));
// const SystemManagerNew = lazy(() => import('pages/SystemManagerNew'));
const SystemManagerEdit = lazy(() => import('pages/SystemManagerEdit'));
// const AllJobPosting = lazy(() => import('pages/JobPosting'));
// const JobNew = lazy(() => import('pages/JobNew'));
// const JobEdit = lazy(() => import('pages/JobEdit'));
// const JobCopy = lazy(() => import('pages/JobCopy'));
// const JobCancel = lazy(() => import('pages/JobCancel'));
// const JobBulkUpdate = lazy(() => import('pages/JobBulkUpdate'));
// const CompanyLocations = lazy(() => import('pages/CompanyLocations'));
// const LocationNew = lazy(() => import('pages/LocationNew'));
// const LocationEdit = lazy(() => import('pages/LocationEdit'));
// const JobApplicant = lazy(() => import('pages/JobApplicant'));
// const LocationFeedbacks = lazy(() => import('pages/CompanyLocationFeedbacks'));
// const AccountSettings = lazy(() => import('pages/AccountSettings'));
// const AreaLocations = lazy(() => import('pages/AreaLocations'));
// const JodCreditInArea = lazy(() => import('pages/JodCreditInArea'));
// const JobHistory = lazy(() => import('pages/JobHistory'));
// const HowToPost = lazy(() => import('pages/HowToPostJobs'));
// const HowToSelections = lazy(() => import('pages/HowToMakeSelections'));
// const HowToClock = lazy(() => import('pages/HowToClock'));
// const Notifications = lazy(() => import('pages/Notification'));
// const LocationProfile = lazy(() => import('pages/LocationProfile'));
// const LocationProfileFeedback = lazy(() => import('pages/LocationProfileFeedback'));
// const AreaLocationFeedbacks = lazy(() => import('pages/AreaLocationFeedbacks'));
// const JodCreditInLocation = lazy(() => import('pages/JodCreditInLocation'));
const ForgotPassword = lazy(() => import('pages/ForgotPassword'));
const ResetPassword = lazy(() => import('pages/ResetPassword'));

// const InternalUser = lazy(() => import('pages/InternalUser'));
// const InternalUserNew = lazy(() => import('pages/InternalUserNew'));
// const InternalUserEdit = lazy(() => import('pages/InternalUserEdit'));
const UserRole = lazy(() => import('pages/UserRole'));
// const UserRoleNew = lazy(() => import('pages/UserRoleNew'));
// const UserRoleEdit = lazy(() => import('pages/UserRoleEdit'));
// const UserPartnerRoleEdit = lazy(() => import('pages/UserPartnerRoleEdit'));
const Permission = lazy(() => import('pages/Permission'));
const NotificationConfiguration = lazy(() => import('pages/NotificationConfiguration'));
const OtherConfiguration = lazy(() => import('pages/OtherConfiguration'));
// const BadgeConfiguration = lazy(() => import('pages/BadgeConfiguration'));
const AppConfiguration = lazy(() => import('pages/AppConfiguration'));
// const PartnerSignUp = lazy(() => import('pages/PartnerSignUp'));
// const SuperHqToggleView = lazy(() => import('pages/Dashboard/SuperHqToggleView'));
// const SuperHqUser = lazy(() => import('pages/SuperHqUser'));
// const SuperHqUserEdit = lazy(() => import('pages/SuperHqUserEdit'));
// const SuperHqUserNew = lazy(() => import('pages/SuperHqUserNew'));
// const VerifyHqEmail = lazy(() => import('pages/VerificationPageEmail'));

const Schedule = lazy(() => import('pro/pages/Schedule'));
const JobTemplate = lazy(() => import('pro/pages/JobTemplate'));
const PaymentRules = lazy(() => import('pro/pages/PaymentRules'));
const WorkforceManager = lazy(() => import('pro/pages/WorkforceManager'));
const WorkforceWorker = lazy(() => import('pro/pages/WorkforceWorker'));
const Outlet = lazy(() => import('pro/pages/Outlet'));
const Payroll = lazy(() => import('pro/pages/Payroll'));
const AccountSettings = lazy(() => import('pro/pages/AccountSettings'));
const Company = lazy(() => import('pages/Company'));
const Companies = lazy(() => import('pages/Companies'));

export const publicRoutes: RouteConfig[] = [
  {
    path: PATH_NAME.ERROR_404,
    element: Error404,
    metaData: { title: 'title.404PageNotFound' },
    excludeMainLayout: true,
  },
  {
    path: PATH_NAME.LOGIN,
    //  guard: GuestRouteGuard,
    element: Login,
    excludeMainLayout: true,
  },
  // {
  //   path: PATH_NAME.PARTNER_SIGN_UP,
  //   //  guard: GuestRouteGuard,
  //   element: PartnerSignUp,
  //   excludeMainLayout: true,
  // },
  {
    path: PATH_NAME.FORGOT_PASSWORD,
    //  guard: GuestRouteGuard,
    element: ForgotPassword,
    excludeMainLayout: true,
  },
  {
    path: PATH_NAME.RESET_PASSWORD,
    //  guard: GuestRouteGuard,
    element: ResetPassword,
    excludeMainLayout: true,
  },
  // {
  //   path: PATH_NAME.VERIFY_EMAIL,
  //   //  guard: GuestRouteGuard,
  //   element: VerifyHqEmail,
  // },
  // {
  //   path: PATH_NAME.VERIFY_EMAIL,
  //   //  guard: GuestRouteGuard,
  //   element: VerifyHqEmail,
  // },
];

export const internalRoutes: RouteConfig[] = [
  {
    path: PATH_NAME.DASHBOARD,
    title: 'title.dashboard',
    // element: InternalAdminDashboard,
    //  guard: RolePermissionRouteGuard,
    // permissions: [
    //   InternalPermission.VIEW_COMPANY,
    //   InternalPermission.VIEW_HQ_USER,
    //   InternalPermission.VIEW_PAYMENT_FUTURE,
    //   InternalPermission.VIEW_PAYMENT_SUMMARY,
    //   InternalPermission.CHANGE_DASHBOARD_LOGO,
    //   InternalPermission.VIEW_JOB_TYPE,
    //   InternalPermission.VIEW_LOCATION,
    //   InternalPermission.VIEW_APPLICANT,
    //   InternalPermission.VIEW_EDUCATIONAL_INSTITUTE,
    // ],
    type: 'internal',
  },
  //   {
  //     path: PATH_NAME.COMPANY_EDIT(),
  //     element: CompanyEdit,
  // //  guard: RolePermissionRouteGuard,
  //     metaData: { title: 'title.newCompany' },
  //     title: 'title.editCompany',
  //     permissions: [InternalPermission.UPDATE_COMPANY],
  //     type: 'internal',
  //   },
  // {
  //   path: PATH_NAME.JOB_TYPE_NEW,
  //   element: JobTypeNew,
  //   //  guard: RolePermissionRouteGuard,
  //   title: 'title.newJobType',
  //   metaData: { title: 'title.newJobType' },
  //   permissions: [InternalPermission.CREATE_JOB_TYPE],
  //   type: 'internal',
  // },
  // {
  //   path: PATH_NAME.JOB_TYPE_EDIT(null),
  //   element: JobTypeEdit,
  //   //  guard: RolePermissionRouteGuard,
  //   title: 'title.editJobType',
  //   metaData: { title: 'title.editJobType' },
  //   permissions: [InternalPermission.UPDATE_JOB_TYPE],
  //   type: 'internal',
  // },
  // {
  //   path: PATH_NAME.JOB_TYPE,
  //   element: JobTypeList,
  //   //  guard: RolePermissionRouteGuard,
  //   title: 'title.listOfJobTypes',
  //   metaData: { title: 'title.listOfJobTypes' },
  //   permissions: [InternalPermission.VIEW_JOB_TYPE],
  //   type: 'internal',
  // },
  // // {
  // //   path: PATH_NAME.HQ_USER_EDIT(null),
  // //   element: HqUserEdit,
  // //   //  guard: RolePermissionRouteGuard,
  // //   title: 'title.editHQUsers',
  // //   metaData: { title: 'title.editHQUsers' },
  // //   permissions: [InternalPermission.UPDATE_HQ_USER],
  // //   type: 'internal',
  // // },
  // // {
  // //   path: PATH_NAME.HQ_USER_NEW,
  // //   element: HqUserNew,
  // //   //  guard: RolePermissionRouteGuard,
  // //   title: 'title.newHQUsers',
  // //   // breakLineTitle: ['HQ MANAGER', 'REGISTRATION'],
  // //   metaData: { title: 'title.newHQUsers' },
  // //   permissions: [InternalPermission.CREATE_HQ_USER],
  // //   type: 'internal',
  // // },
  // // {
  // //   path: PATH_NAME.HQ_USER,
  // //   element: HqUser,
  // //   //  guard: RolePermissionRouteGuard,
  // //   title: 'title.listOfHQUsers',
  // //   metaData: { title: 'title.listOfHQUsers' },
  // //   permissions: [InternalPermission.VIEW_HQ_USER],
  // //   type: 'internal',
  // // },
  // // {
  // //   path: PATH_NAME.MANAGER_NEW,
  // //   element: SystemManagerNew,
  // //   roles: [Role.SuperAdmin, Role.Internal, Role.UserAdmin],
  // //   //  guard: RolePermissionRouteGuard,
  // //   title: 'title.managerRegistration',
  // //   // breakLineTitle: ['MANAGER', 'REGISTRATION'],
  // //   metaData: { title: 'title.managerRegistration' },
  // //   permissions: [InternalPermission.SA_CREATE_MANAGER],
  // //   type: 'internal',
  // // },
  // {
  //   path: PATH_NAME.MANAGER_EDIT(),
  //   element: SystemManagerEdit,
  //   roles: [Role.SuperAdmin, Role.Internal, Role.UserAdmin],
  //   //  guard: RolePermissionRouteGuard,
  //   title: 'title.editManager',
  //   metaData: { title: 'title.editManager' },
  //   permissions: [InternalPermission.SA_UPDATE_MANAGER],
  //   type: 'internal',
  // },
  // {
  //   path: PATH_NAME.MANAGER,
  //   element: SystemManager,
  //   roles: [Role.SuperAdmin, Role.Internal, Role.UserAdmin],
  //   //  guard: RolePermissionRouteGuard,
  //   title: 'title.listOfManagers',
  //   metaData: { title: 'title.listOfManagers' },
  //   permissions: [InternalPermission.VIEW_HIRING_MANAGERS],
  //   type: 'internal',
  // },
  // // {
  // //   path: PATH_NAME.MANAGER,
  // //   element: ManagerList,
  // //   roles: [Role.SuperAdmin],
  // //   guard: RolePermissionRouteGuard,
  // //   title: 'title.listOfManagers',
  // //   metaData: { title: 'title.listOfManagers' },
  // //   permissions: [InternalPermission.VIEW_HIRING_MANAGERS],
  // //   type: 'internal',
  // // },
  // {
  //   path: PATH_NAME.APPLICANT_NEW,
  //   element: ApplicantNew,
  //   //  guard: RolePermissionRouteGuard,
  //   title: 'title.newApplicant',
  //   metaData: { title: 'title.newApplicant' },
  //   permissions: [InternalPermission.CREATE_APPLICANT],
  //   type: 'internal',
  // },
  // {
  //   path: PATH_NAME.APPLICANT_EDIT(),
  //   element: ApplicantEdit,
  //   //  guard: RolePermissionRouteGuard,
  //   title: 'title.editApplicant',
  //   metaData: { title: 'title.editApplicant' },
  //   permissions: [InternalPermission.UPDATE_APPLICANT],
  //   type: 'internal',
  // },
  // {
  //   path: PATH_NAME.APPLICANT,
  //   element: Applicant,
  //   //  guard: RolePermissionRouteGuard,
  //   title: 'title.listOfApplicants',
  //   metaData: { title: 'title.listOfApplicants' },
  //   permissions: [InternalPermission.VIEW_APPLICANT],
  //   type: 'internal',
  // },
  // {
  //   path: PATH_NAME.EDUCATION_INSTITUTE_NEW,
  //   element: EducationalInstituteNew,
  //   //  guard: RolePermissionRouteGuard,
  //   title: 'title.newEducationalInstitute',
  //   metaData: { title: 'title.newEducationalInstitute' },
  //   // breakLineTitle: ['NEW EDUCATIONAL', 'INSTITUTE'],
  //   permissions: [InternalPermission.CREATE_EDUCATIONAL_INSTITUTE],
  //   type: 'internal',
  // },
  // {
  //   path: PATH_NAME.EDUCATION_INSTITUTE_EDIT(null),
  //   element: EducationalInstituteEdit,
  //   //  guard: RolePermissionRouteGuard,
  //   title: 'title.editEducationalInstitute',
  //   metaData: { title: 'title.editEducationalInstitute' },
  //   // breakLineTitle: ['EDIT EDUCATIONAL', 'INSTITUTE'],
  //   permissions: [InternalPermission.UPDATE_EDUCATIONAL_INSTITUTE],
  //   type: 'internal',
  // },
  // {
  //   path: PATH_NAME.EDUCATION_INSTITUTE,
  //   element: EducationalInstitute,
  //   //  guard: RolePermissionRouteGuard,
  //   title: 'title.listOfEducationInstitutes',
  //   metaData: { title: 'title.listOfEducationInstitutes' },
  //   // breakLineTitle: ['LIST OF', 'EDUCATIONAL INSTITUTES'],
  //   permissions: [InternalPermission.VIEW_EDUCATIONAL_INSTITUTE],
  //   type: 'internal',
  // },
  // {
  //   path: PATH_NAME.BILLING_RECORD,
  //   element: BillingRecord,
  //   //  guard: RolePermissionRouteGuard,
  //   title: 'title.billingRecords',
  //   metaData: { title: 'title.billingRecords' },
  //   permissions: [InternalPermission.VIEW_BILLING_RECORD],
  //   type: 'internal',
  // },
  // // {
  // //   path: PATH_NAME.JOD_CREDIT_ASSIGN,
  // //   element: CompanyAssignCredit,
  // //   //  guard: RolePermissionRouteGuard,
  // //   title: 'title.assignCredit',
  // //   metaData: { title: 'title.assignCredit' },
  // //   permissions: [InternalPermission.ASSIGN_CREDIT],
  // //   type: 'internal',
  // // },
  // // {
  // //   path: PATH_NAME.JOD_CREDIT,
  // //   element: CompanyCreditHistory,
  // //   //  guard: RolePermissionRouteGuard,
  // //   title: 'title.creditHistory',
  // //   metaData: { title: 'title.creditHistory' },
  // //   permissions: [InternalPermission.VIEW_COMPANY_CREDIT_HISTORY],
  // //   type: 'internal',
  // // },
  // // {
  // //   path: PATH_NAME.JOD_DEBIT_CREDIT,
  // //   element: CompanyDebitCreditHistory,
  // //   //  guard: RolePermissionRouteGuard,
  // //   title: 'title.debitCreditHistory',
  // //   metaData: { title: 'title.debitCreditHistory' },
  // //   permissions: [InternalPermission.VIEW_COMPANY_DEBIT_CREDIT_HISTORY],
  // //   type: 'internal',
  // // },
  // {
  //   path: PATH_NAME.LOCATION,
  //   element: SystemLocations,
  //   //  guard: RolePermissionRouteGuard,
  //   title: 'title.listOfLocations',
  //   metaData: { title: 'title.listOfLocations' },
  //   permissions: [InternalPermission.VIEW_LOCATION],
  //   type: 'internal',
  // },
  // {
  //   path: PATH_NAME.PAYMENT_SUMMARY,
  //   element: PaymentSummary,
  //   //  guard: RolePermissionRouteGuard,
  //   title: 'title.jobSummary',
  //   metaData: { title: 'title.jobSummary' },
  //   permissions: [InternalPermission.VIEW_PAYMENT_SUMMARY],
  //   type: 'internal',
  // },
  // {
  //   path: PATH_NAME.PAYMENT_PROCESSED,
  //   element: PaymentProcessed,
  //   //  guard: RolePermissionRouteGuard,
  //   title: 'title.jobPayments',
  //   metaData: { title: 'title.jobPayments' },
  //   permissions: [InternalPermission.VIEW_PAYMENT_PROCESSED],
  //   type: 'internal',
  // },
  // {
  //   path: PATH_NAME.PAYMENT_FUTURE,
  //   element: PaymentFuture,
  //   //  guard: RolePermissionRouteGuard,
  //   title: 'title.futurePayments',
  //   metaData: { title: 'title.futurePayments' },
  //   permissions: [InternalPermission.VIEW_PAYMENT_FUTURE],
  //   type: 'internal',
  // },
  // {
  //   path: PATH_NAME.REPORT,
  //   element: ReportList,
  //   //  guard: RolePermissionRouteGuard,
  //   title: 'title.report',
  //   metaData: { title: 'title.report' },
  //   permissions: [InternalPermission.VIEW_REPORT],
  //   type: 'internal',
  // },
  // // {
  // //   path: PATH_NAME.INTERNAL_USER_EDIT(),
  // //   element: InternalUserEdit,
  // //   //  guard: RolePermissionRouteGuard,
  // //   title: 'title.editInternalUser',
  // //   metaData: { title: 'title.editInternalUser' },
  // //   permissions: [InternalPermission.UPDATE_INTERNAL_USER],
  // //   type: 'internal',
  // // },
  // // {
  // //   path: PATH_NAME.SUPERHQ_USER_EDIT(),
  // //   element: SuperHqUserEdit,
  // //   //  guard: RolePermissionRouteGuard,
  // //   title: 'title.editInternalUser',
  // //   metaData: { title: 'title.editInternalUser' },
  // //   permissions: [InternalPermission.UPDATE_INTERNAL_USER],
  // //   type: 'internal',
  // // },
  // // {
  // //   path: PATH_NAME.INTERNAL_USER_NEW,
  // //   element: InternalUserNew,
  // //   //  guard: RolePermissionRouteGuard,
  // //   title: 'button.addNewUser',
  // //   metaData: { title: 'button.addNewUser' },
  // //   permissions: [InternalPermission.CREATE_INTERNAL_USER],
  // //   type: 'internal',
  // // },
  // // {
  // //   path: PATH_NAME.SUPERHQ_USER_NEW,
  // //   element: SuperHqUserNew,
  // //   //  guard: RolePermissionRouteGuard,
  // //   title: 'button.addNewUser',
  // //   metaData: { title: 'button.addNewUser' },
  // //   permissions: [InternalPermission.CREATE_INTERNAL_USER],
  // //   type: 'internal',
  // // },
  // // {
  // //   path: PATH_NAME.INTERNAL_USER,
  // //   element: InternalUser,
  // //   //  guard: RolePermissionRouteGuard,
  // //   title: 'title.users',
  // //   metaData: { title: 'title.users' },
  // //   permissions: [InternalPermission.VIEW_INTERNAL_USER],
  // //   type: 'internal',
  // // },
  // // {
  // //   path: PATH_NAME.SUPERHQ_USER,
  // //   element: SuperHqUser,
  // //   //  guard: RolePermissionRouteGuard,
  // //   title: 'title.superHqUsers',
  // //   metaData: { title: 'title.superHqUsers' },
  // //   permissions: [InternalPermission.VIEW_INTERNAL_USER],
  // //   type: 'internal',
  // // },
  // // {
  // //   path: PATH_NAME.INTERNAL_ROLE_NEW,
  // //   element: UserRoleNew,
  // //   //  guard: RolePermissionRouteGuard,
  // //   title: 'title.newRole',
  // //   metaData: { title: 'title.newRole' },
  // //   permissions: [InternalPermission.CREATE_ROLE],
  // //   type: 'internal',
  // // },
  // // {
  // //   path: PATH_NAME.INTERNAL_ROLE_EDT(),
  // //   element: UserRoleEdit,
  // //   //  guard: RolePermissionRouteGuard,
  // //   title: 'title.editRole',
  // //   metaData: { title: 'title.editRole' },
  // //   permissions: [InternalPermission.UPDATE_ROLE],
  // //   type: 'internal',
  // // },
  // // {
  // //   path: PATH_NAME.PARTNER_ROLE_EDT(),
  // //   element: UserPartnerRoleEdit,
  // //   //  guard: RolePermissionRouteGuard,
  // //   title: 'title.editRole',
  // //   metaData: { title: 'title.editRole' },
  // //   permissions: [InternalPermission.UPDATE_ROLE],
  // //   type: 'internal',
  // // },
  // {
  //   path: PATH_NAME.INTERNAL_ROLE,
  //   element: UserRole,
  //   //  guard: RolePermissionRouteGuard,
  //   title: 'title.roles',
  //   metaData: { title: 'title.roles' },
  //   permissions: [InternalPermission.VIEW_ROLE],
  //   type: 'internal',
  // },
  // {
  //   path: PATH_NAME.PERMISSION,
  //   element: Permission,
  //   //  guard: RolePermissionRouteGuard,
  //   title: 'label.permission',
  //   metaData: { title: 'label.permission' },
  //   permissions: [InternalPermission.VIEW_PERMISSION],
  //   type: 'internal',
  // },
  // {
  //   path: PATH_NAME.CONFIGURATION_NOTIFICATIONS,
  //   element: NotificationConfiguration,
  //   //  guard: RolePermissionRouteGuard,
  //   title: 'title.notificationConfigurations',
  //   metaData: { title: 'title.notificationConfigurations' },
  //   permissions: [InternalPermission.UPDATE_NOTIFICATION_CONFIGURATION],
  //   type: 'internal',
  // },
  // {
  //   path: PATH_NAME.CONFIGURATION_JOD_BADGE,
  //   element: BadgeConfiguration,
  //   //  guard: RolePermissionRouteGuard,
  //   title: 'title.jodBadgesConfiguration',
  //   metaData: { title: 'title.jodBadgesConfiguration' },
  //   permissions: [InternalPermission.UPDATE_JOD_BADGE_CONFIGURATION],
  //   type: 'internal',
  // },
  // {
  //   path: PATH_NAME.CONFIGURATION_OTHER,
  //   element: OtherConfiguration,
  //   //  guard: RolePermissionRouteGuard,
  //   title: 'title.otherConfigurations',
  //   metaData: { title: 'title.otherConfigurations' },
  //   permissions: [InternalPermission.UPDATE_OTHER_CONFIGURATION],
  //   type: 'internal',
  // },
  // {
  //   path: PATH_NAME.CONFIGURATION_APP,
  //   element: AppConfiguration,
  //   //  guard: RolePermissionRouteGuard,
  //   title: 'title.appConfig',
  //   metaData: { title: 'title.appConfig' },
  //   permissions: [InternalPermission.UPDATE_OTHER_CONFIGURATION],
  //   type: 'internal',
  // },
];

export const partnerRoutes: RouteConfig[] = [
  // {
  //   path: PATH_NAME.ACCOUNT_SETTINGS,
  //   element: AccountSettings,
  //   title: 'title.editProfile',
  //   metaData: { title: 'title.editProfile' },
  //   type: 'all',
  // },
  // {
  //   path: PATH_NAME.DASHBOARD,
  //   title: 'title.dashboard',
  //   //  guard: RolePermissionRouteGuard,
  //   roleComponents: {
  //     // [Role.HqManager]: HqAdminDashboard,
  //     // [Role.AreaManager]: AreaAdminViewDashboard,
  //     // [Role.LocationManager]: LocationAdminViewDashboard,
  //   },
  //   permissions: [
  //     PartnerPermission.VIEW_JOB_OVERVIEW,
  //     PartnerPermission.VIEW_HIRING_OVERVIEW,
  //     PartnerPermission.VIEW_CREDIT_OVERVIEW,
  //     PartnerPermission.VIEW_RATING_OVERVIEW,
  //     PartnerPermission.DOWNLOAD_SERVICE_CONTRACT,
  //   ],
  //   type: 'partner',
  // },
  // {
  //   path: PATH_NAME.JOB_TEMPLATE_NEW,
  //   element: JobTemplateNew,
  //   //  guard: RolePermissionRouteGuard,
  //   title: 'title.addNewJobTemplate',
  //   metaData: { title: 'title.addNewJobTemplate' },
  //   permissions: [PartnerPermission.CREATE_JOB_TEMPLATE],
  //   type: 'partner',
  // },
  // {
  //   path: PATH_NAME.JOB_TEMPLATE_EDIT(),
  //   element: JobTemplateEdit,
  //   //  guard: RolePermissionRouteGuard,
  //   title: 'title.editJobTemplate',
  //   metaData: { title: 'title.editJobTemplate' },
  //   permissions: [PartnerPermission.UPDATE_JOB_TEMPLATE],
  //   type: 'partner',
  // },
  // {
  //   path: PATH_NAME.JOB_TEMPLATE,
  //   element: JobTemplateList,
  //   //  guard: RolePermissionRouteGuard,
  //   title: 'title.jobTemplates',
  //   metaData: { title: 'title.jobTemplates' },
  //   permissions: [PartnerPermission.VIEW_JOB_TEMPLATE],
  //   type: 'partner',
  // },
  // {
  //   path: PATH_NAME.MANAGER_NEW,
  //   element: ManagerNew,
  //   roles: [Role.HqManager],
  //   //  guard: RolePermissionRouteGuard,
  //   title: 'title.managerRegistration',
  //   // breakLineTitle: ['MANAGER', 'REGISTRATION'],
  //   metaData: { title: 'title.managerRegistration' },
  //   permissions: [PartnerPermission.CREATE_MANAGER],
  //   type: 'partner',
  // },
  // {
  //   path: PATH_NAME.MANAGER_EDIT(),
  //   element: ManagerEdit,
  //   roles: [Role.HqManager],
  //   //  guard: RolePermissionRouteGuard,
  //   title: 'title.editManager',
  //   metaData: { title: 'title.editManager' },
  //   permissions: [PartnerPermission.UPDATE_MANAGER],
  //   type: 'partner',
  // },
  // {
  //   path: PATH_NAME.MANAGER,
  //   element: ManagerList,
  //   roles: [Role.HqManager],
  //   //  guard: RolePermissionRouteGuard,
  //   title: 'title.listOfManagers',
  //   metaData: { title: 'title.listOfManagers' },
  //   permissions: [PartnerPermission.VIEW_MANAGER],
  //   type: 'partner',
  // },
  // {
  //   path: PATH_NAME.LOCATION_NEW,
  //   element: LocationNew,
  //   roles: [Role.HqManager],
  //   //  guard: RolePermissionRouteGuard,
  //   title: 'title.addNewLocation',
  //   metaData: { title: 'title.addNewLocation' },
  //   permissions: [PartnerPermission.CREATE_LOCATION],
  //   type: 'partner',
  // },
  // {
  //   path: PATH_NAME.LOCATION_EDIT(),
  //   element: LocationEdit,
  //   roles: [Role.HqManager],
  //   //  guard: RolePermissionRouteGuard,
  //   title: 'title.editLocation',
  //   metaData: { title: 'title.editLocation' },
  //   permissions: [PartnerPermission.UPDATE_LOCATION],
  //   type: 'partner',
  // },
  // // {
  // //   path: PATH_NAME.LOCATION_FEEDBACK(),
  // //   roleComponents: {
  // //     [Role.HqManager]: LocationFeedbacks,
  // //     [Role.AreaManager]: AreaLocationFeedbacks,
  // //   },
  // //   roles: [Role.HqManager, Role.AreaManager],
  // //   //  guard: RolePermissionRouteGuard,
  // //   title: 'title.locationFeedback',
  // //   // breakLineTitle: ['LOCATION', 'FEEDBACK'],
  // //   metaData: { title: 'title.locationFeedback' },
  // //   permissions: [PartnerPermission.VIEW_LOCATION_FEEDBACK],
  // //   type: 'partner',
  // // },
  // // {
  // //   path: PATH_NAME.LOCATION,
  // //   roles: [Role.HqManager, Role.AreaManager],
  // //   //  guard: RolePermissionRouteGuard,
  // //   roleComponents: {
  // //     [Role.HqManager]: CompanyLocations,
  // //     [Role.AreaManager]: AreaLocations,
  // //   },
  // //   title: 'title.listOfLocations',
  // //   metaData: { title: 'title.listOfLocations' },
  // //   permissions: [PartnerPermission.VIEW_LOCATION],
  // //   type: 'partner',
  // // },
  // {
  //   path: PATH_NAME.CANDIDATE_JOB(),
  //   element: JobApplicant,
  //   //  guard: RolePermissionRouteGuard,
  //   title: '',
  //   metaData: { title: 'title.candidatesOfJob' },
  //   permissions: [PartnerPermission.VIEW_CANDIDATE],
  //   type: 'partner',
  // },
  // {
  //   path: PATH_NAME.CANDIDATE,
  //   element: JobApplicant,
  //   //  guard: RolePermissionRouteGuard,
  //   title: 'title.listOfAllCandidates',
  //   metaData: { title: 'title.listOfAllCandidates' },
  //   // breakLineTitle: ['LIST OF', 'ALL CANDIDATES'],
  //   permissions: [PartnerPermission.VIEW_CANDIDATE],
  //   type: 'partner',
  // },
  // {
  //   path: PATH_NAME.JOB_NEW,
  //   element: JobNew,
  //   //  guard: RolePermissionRouteGuard,
  //   title: 'title.createNewJob',
  //   metaData: { title: 'title.createNewJob' },
  //   permissions: [PartnerPermission.CREATE_JOB],
  //   type: 'partner',
  // },
  // {
  //   path: PATH_NAME.JOB_COPY(),
  //   element: JobCopy,
  //   //  guard: RolePermissionRouteGuard,
  //   title: 'title.copyJob',
  //   metaData: { title: 'title.copyJob' },
  //   permissions: [PartnerPermission.CREATE_JOB],
  //   type: 'partner',
  // },
  // {
  //   path: PATH_NAME.JOB_EDIT(),
  //   element: JobEdit,
  //   //  guard: RolePermissionRouteGuard,
  //   title: 'title.jobEdit',
  //   metaData: { title: 'title.jobEdit' },
  //   permissions: [PartnerPermission.CREATE_JOB],
  //   type: 'partner',
  // },
  // {
  //   path: PATH_NAME.JOB,
  //   element: AllJobPosting,
  //   //  guard: RolePermissionRouteGuard,
  //   title: 'title.allJobPosting',
  //   metaData: { title: 'title.allJobPosting' },
  //   permissions: [PartnerPermission.VIEW_JOB],
  //   type: 'partner',
  // },
  // {
  //   path: PATH_NAME.JOB_CANCEL,
  //   element: JobCancel,
  //   roles: [Role.HqManager],
  //   //  guard: RolePermissionRouteGuard,
  //   title: 'title.cancelJobs',
  //   metaData: { title: 'title.cancelJobs' },
  //   permissions: [PartnerPermission.CANCEL_MULTIPLE_JOB],
  //   type: 'partner',
  // },
  // {
  //   path: PATH_NAME.JOB_BULK_UPDATE,
  //   element: JobBulkUpdate,
  //   roles: [Role.HqManager],
  //   //  guard: RolePermissionRouteGuard,
  //   title: 'title.updateJobs',
  //   metaData: { title: 'title.updateJobs' },
  //   permissions: [PartnerPermission.BULK_UPDATE_JOB_WAGE],
  //   type: 'partner',
  // },
  // {
  //   path: PATH_NAME.HOW_TO_POST_JOBS,
  //   element: HowToPost,
  //   roles: [Role.HqManager, Role.AreaManager, Role.LocationManager],
  //   //  guard: RolePermissionRouteGuard,
  //   title: 'title.howToPostJobs',
  //   metaData: { title: 'title.howToPostJobs' },
  //   permissions: [PartnerPermission.VIEW_POST_JOBS],
  //   type: 'partner',
  // },
  // {
  //   path: PATH_NAME.HOW_TO_MAKE_SELECTIONS,
  //   element: HowToSelections,
  //   roles: [Role.HqManager, Role.AreaManager, Role.LocationManager],
  //   //  guard: RolePermissionRouteGuard,
  //   title: 'title.howToMakeSelections',
  //   metaData: { title: 'title.howToMakeSelections' },
  //   permissions: [PartnerPermission.VIEW_MAKE_SELECTIONS],
  //   type: 'partner',
  // },
  // {
  //   path: PATH_NAME.HOW_TO_CLOCK_IN_AND_OUT,
  //   element: HowToClock,
  //   roles: [Role.HqManager, Role.AreaManager, Role.LocationManager],
  //   //  guard: RolePermissionRouteGuard,
  //   title: 'title.howToClockInAndOut',
  //   metaData: { title: 'title.howToClockInAndOut' },
  //   permissions: [PartnerPermission.VIEW_CLOCK_IN_AND_OUT],
  //   type: 'partner',
  // },
  // // {
  // //   path: PATH_NAME.LOCATION_PROFILE_FEEDBACK,
  // //   element: LocationProfileFeedback,
  // //   title: 'title.locationFeedback',
  // //   metaData: { title: 'title.locationFeedback' },
  // //   roles: [Role.LocationManager],
  // //   //  guard: RolePermissionRouteGuard,
  // //   permissions: [PartnerPermission.VIEW_LOCATION_FEEDBACK],
  // //   type: 'partner',
  // // },
  // // {
  // //   path: PATH_NAME.LOCATION_PROFILE,
  // //   element: LocationProfile,
  // //   title: 'title.locationProfile',
  // //   metaData: { title: 'title.locationProfile' },
  // //   roles: [Role.LocationManager],
  // //   //  guard: RolePermissionRouteGuard,
  // //   permissions: [PartnerPermission.VIEW_LOCATION],
  // //   type: 'partner',
  // // },
  // {
  //   path: PATH_NAME.JOB_HISTORY,
  //   element: JobHistory,
  //   roles: [Role.AreaManager, Role.HqManager, Role.LocationManager],
  //   //  guard: RolePermissionRouteGuard,
  //   title: 'title.jobHistory',
  //   metaData: { title: 'title.jobHistory' },
  //   permissions: [PartnerPermission.VIEW_JOB_HISTORY],
  //   type: 'partner',
  // },
  // {
  //   path: PATH_NAME.JOD_CREDIT,
  //   roles: [Role.HqManager, Role.AreaManager, Role.LocationManager],
  //   roleComponents: {
  //     [Role.HqManager]: JodCreditInCompany,
  //     [Role.AreaManager]: JodCreditInArea,
  //     [Role.LocationManager]: JodCreditInLocation,
  //   },
  //   //  guard: RolePermissionRouteGuard,
  //   title: 'title.jodCredits',
  //   metaData: { title: 'title.jodCredits' },
  //   permissions: [PartnerPermission.VIEW_CREDIT_HISTORY, PartnerPermission.VIEW_LOCATION_CREDIT],
  //   type: 'partner',
  // },
  {
    path: PATH_NAME.DASHBOARD,
    title: 'title.redirecting',
    element: RedirectFirstPage,
  },
  {
    path: '/demo',
    element: Demo,
    metaData: { title: 'Demo' },
    title: 'Demo',
  },
  {
    path: '*',
    element: Error404,
    metaData: { title: '404 Page Not Found' },
  },
  {
    path: PATH_NAME.SCHEDULE,
    element: Schedule,
    type: 'internal',
  },
  {
    path: PATH_NAME.JOB_TEMPLATE,
    element: JobTemplate,
    type: 'internal',
  },
  {
    path: PATH_NAME.PAYMENT_RULES,
    element: PaymentRules,
    type: 'internal',
  },
  {
    path: PATH_NAME.WORKFORCE_MANAGER,
    element: WorkforceManager,
    type: 'internal',
  },
  {
    path: PATH_NAME.WORKFORCE_WORKER,
    element: WorkforceWorker,
    type: 'internal',
  },
  {
    path: PATH_NAME.OUTLET,
    element: Outlet,
    type: 'internal',
  },
  {
    path: PATH_NAME.PAYROLL,
    element: Payroll,
    type: 'internal',
  },
  {
    path: PATH_NAME.ACCOUNT_SETTINGS,
    element: AccountSettings,
    type: 'internal',
  },
  {
    path: PATH_NAME.COMPANIES,
    element: Companies,
    // guard: RolePermissionRouteGuard,
    // metaData: { title: 'title.listOfCompanies' },
    // title: 'title.listOfCompanies',
    // permissions: [InternalPermission.VIEW_COMPANY],
    type: 'internal',
  },
  {
    path: PATH_NAME.COMPANY,
    element: Company,
    // guard: RolePermissionRouteGuard,
    // metaData: { title: 'title.company' },
    // title: 'title.company',
    // permissions: [PartnerPermission.VIEW_COMPANY],
    type: 'partner',
  },
  {
    path: `${PATH_NAME.COMPANIES}/:id`, // Dynamic route for company details
    element: Companies,
    // guard: RolePermissionRouteGuard,
    // //  guard: RolePermissionRouteGuard,
    // metaData: { title: 'title.listOfCompanies' },
    // title: 'title.listOfCompanies',
    // permissions: [InternalPermission.VIEW_COMPANY],
    type: 'internal',
  },
];

export const privateRoutes: RouteConfig[] = concat(internalRoutes, partnerRoutes);
