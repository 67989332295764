import { userStorage } from 'configs/browser-storage'

// get permission action per api 
export const getPermission = (id: number) => {
  const value: any = userStorage.value;
  let list: Array<any> = []
  value?.company_role_permissions.map((role: any) => list = role.permissions)
  return list.find((e: any) => e.id === id)
}

export const getCompanyId = () => {
  const value: any = userStorage.value;
  return value?.company_id;
}

// check userPermission
export const userPermission = (id: number) => {
  const value: any = userStorage.value;
  let list: Array<any> = []
  value?.company_role_permissions.map((role: any) => {
    for (let permissions of role.permissions) {
      list.push(permissions.id)
    }
  })
  return list.includes(id)
}