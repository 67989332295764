import {
  FireWithBadgeIcon,
  LeftOutlinedIcon,
  MenuDashOutlineIcon,
  NotificationWithBadgeIcon,
} from 'assets/icons';
import styled from 'styled-components';
import { useDeviceMode, useRouter } from 'hooks/common';
import { FC, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { configActions, headerTitleSelector, jodAdminSelector } from 'store/modules/config';
import { DeviceMode } from 'types';
import CompanySelector from 'pro/components/CompanySelector';
interface HeaderProps {
  onClick: () => void;
}

const Container = styled.div`
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media (min-width: 1024px) {
    /* Large devices */
    padding: 20px;
    background-color: #f6f7fa;
  }
`;

const HeaderSection = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
`;

const HeaderTitle = styled.h3`
  font-size: 0.875rem;
  font-weight: bold;
  text-transform: uppercase;
  color: black;

  @media (min-width: 1024px) {
    /* Large devices */
    font-size: 2rem;
  }
`;

const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;

  button {
    display: flex;

    @media (min-width: 1024px) {
      /* Large devices */
      display: none; /* Hide on larger screens */
    }
  }
`;

const ResponsiveMenuButton = styled.button`
  display: flex;

  @media (min-width: 1024px) {
    /* Hide menu button on large screens */
    display: none;
  }
`;

const ResponsiveIconContainer = styled.div.attrs<{ isMobile: boolean }>((props) => ({
  style: {
    width: props.isMobile ? '27px' : '41px',
    height: props.isMobile ? '27px' : '41px',
  },
}))<{ isMobile: boolean }>`
  display: flex;
  align-items: center;
`;

const Header: FC<HeaderProps> = ({ onClick }) => {
  const router = useRouter();
  const dispatch = useDispatch();
  const device = useDeviceMode();
  const { headerTitle } = useSelector(headerTitleSelector);
  const JodAdmin = useSelector(jodAdminSelector);

  const handleBack = () => {
    router.navigate('/');
    dispatch(
      configActions.setHeaderTitle({
        headerTitle: '',
      }),
    );
  };
  const isMobile = device === DeviceMode.Mobile;
  return (
    <>
      <Container>
        <HeaderSection>
          <button type="button" onClick={handleBack}>
            <LeftOutlinedIcon />
          </button>
          <HeaderTitle>{headerTitle}</HeaderTitle>
        </HeaderSection>

        <ActionsContainer>
          {!isMobile && JodAdmin && <CompanySelector />}
          <ResponsiveIconContainer isMobile={isMobile}>
            <NotificationWithBadgeIcon />
          </ResponsiveIconContainer>
          <ResponsiveIconContainer isMobile={isMobile}>
            <FireWithBadgeIcon />
          </ResponsiveIconContainer>
          <ResponsiveMenuButton type="button" onClick={onClick}>
            <MenuDashOutlineIcon />
          </ResponsiveMenuButton>
        </ActionsContainer>
      </Container>
      <Container>{isMobile && JodAdmin && <CompanySelector />}</Container>
    </>
  );
};

export default memo(Header);
