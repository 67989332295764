import { FC, useState, useEffect } from 'react';
import { ListAllCompanies } from 'apis/company';
import logo from 'assets/images/Logo.png';
import styled from 'styled-components';
import { Company } from 'pages/Companies';

interface CompanySelectorProps {}

const CompanySelector: FC<CompanySelectorProps> = () => {
  const [companies, setCompanies] = useState<Company[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<Company | null>(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const response = await ListAllCompanies();
        if (response?.results) {
          setCompanies(response.results);
          setSelectedCompany(response.results[0]);
        }
      } catch (error) {
        console.error('Failed to fetch companies:', error);
      }
    };
    fetchCompanies();
  }, []);

  const handleSelectCompany = (company: Company) => {
    // Update user profile
    setSelectedCompany(company);
    setDropdownOpen(false);
  };

  return (
    <SelectorContainer>
      <SelectedCompany onClick={() => setDropdownOpen(!dropdownOpen)}>
        {selectedCompany && (
          <>
            <CompanyLogo
              src={selectedCompany.company_logo_filename || logo}
              alt={`${selectedCompany.name} logo`}
            />
            <span>{selectedCompany.name}</span>
          </>
        )}
      </SelectedCompany>

      {dropdownOpen && (
        <Dropdown>
          {companies.map((company) => (
            <DropdownItem key={company.id} onClick={() => handleSelectCompany(company)}>
              <CompanyLogo
                src={company.company_logo_filename || logo}
                alt={`${company.name} logo`}
              />
              <span>{company.name}</span>
            </DropdownItem>
          ))}
        </Dropdown>
      )}
    </SelectorContainer>
  );
};

export default CompanySelector;

// Styled Components
const SelectorContainer = styled.div`
  position: relative;
  width: 200px;
`;

const SelectedCompany = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 4px;
`;

const CompanyLogo = styled.img`
  width: 30px;
  height: 30px;
  object-fit: contain;
  margin-right: 8px;
`;

const Dropdown = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
`;

const DropdownItem = styled.div`
  display: flex;
  align-items: center;
  padding: 5px;
  cursor: pointer;

  &:hover {
    background-color: #f0f0f0;
  }
`;
