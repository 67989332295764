import { useMemo } from 'react';

import { Role } from 'models/user';

import { useCurrentRole } from 'hooks/components/auth';

import { RouteConfig, privateRoutes } from './routes';

export const useAuthorizedRoutes = () => {
  const currentRole = useCurrentRole();
  const isPartnerUser =
    currentRole && [Role.AreaManager, Role.HqManager, Role.LocationManager].includes(currentRole);

  const isSuperHqUser =
    currentRole && [Role.SuperHqInternal, Role.SuperHqExternal].includes(currentRole);

  const authorizedRoutes = useMemo<RouteConfig[]>(() => {
    const routes: RouteConfig[] = [];
    // if (!currentRole) return routes;
    for (let currRoute of privateRoutes) {
      // if (currRoute.roles?.length === 0 && currRoute.permissions?.length === 0) break;

      // const haveMultiComponent =
      //   !!currRoute.roleComponents && Object.values(currRoute.roleComponents).length > 0;
      // const matchedComponent = currRoute.roleComponents
      //   ? currRoute.roleComponents['SUPER_ADMIN']
      //   : undefined;

      // if (haveMultiComponent && matchedComponent) {
      //   currRoute = { ...currRoute, element: matchedComponent };
      // }
      // const { type } = currRoute;

      // const isValidRole =
      //   type === 'all' ? true : isSuperHqUser ? type === 'superHq' : isPartnerUser ? type === 'partner' : type === 'internal';

      // if (isValidRole && currRoute.element) {
      // }
      //   routes.push(currRoute);
      // }
      routes.push(currRoute);
      // routes.push(currRoute);

    }
    return routes;
  }, [currentRole, isPartnerUser, isSuperHqUser]);

  return authorizedRoutes;
};
