export const en = {
  // START BUTTON
  'button.allCandidates': 'All Candidates',
  'button.assignCredit': 'Assign Credit',
  'button.rating': 'Rating',
  'button.download': 'Download',
  'button.filter': 'Filter',
  'button.sign_in': 'Sign in',
  'button.submit': 'Submit',
  'button.save': 'Save',
  'button.cancel': 'Cancel',
  'button.close': 'Close',
  'button.newLocation': 'New Location',
  'button.reset': 'Reset',
  'button.resetFilters': 'Reset Filters',
  'button.confirm': 'Confirm',
  'button.yesIDo': 'YES, I DO',
  'button.noIDoNot': 'No, I Do Not',
  'button.addCompany': 'Add New Company',
  'button.addJobType': 'Add New Job Type',
  'button.addManager': 'Add New Manager',
  'button.addApplicant': 'Add New Applicant',
  'button.addNewSchool': 'Add New School',
  'button.addNewRole': 'Add New Role',
  'button.addNewUser': 'Add New User',
  'button.addNewInternalRole': 'Add New Internal Role',
  'button.addNewInternalUser': 'Add New Internal User',
  'button.addNewSuperHqUser': 'Add New Super HQ User',
  'button.addNewHQManager': 'Add New HQ Manager',
  'button.credit': 'Credit',
  'button.view': 'View',
  'button.edit': 'Edit',
  'button.yes': 'Yes, I Do',
  'button.newManager': 'New Manager',
  'button.manager': 'Manager',
  'button.enable': 'Enable',
  'button.disable': 'Disable',
  'button.newJobTemplate': 'New Job Template',

  'button.bulkFastExport': 'Bulk Fast Export',
  'button.downloadPayment': 'Download Payment',
  'button.downloadApplicantJobSummary': `Applicant's Job Summary`,
  'button.copy': 'Copy',
  'button.delete': 'Delete',
  'button.approveJob': 'Approve Job',
  'button.rejectJob': 'Reject Job',
  'button.copyJob': 'Copy Job',
  'button.deleteJob': 'Delete Job',
  'button.editJob': 'Edit Job',
  'button.done': 'Done',
  'button.back': 'Back',
  'button.profile': 'Profile',
  'button.continue': 'Continue',
  'button.updateHourlyRate': 'Update Hourly Rate',
  'button.changePassword': 'Change Password',
  'button.assign': 'Assign',
  'button.logOut': 'Log Out',
  'button.ok': 'Ok',
  'button.detail': 'Detail',
  'button.companyHistory': 'Company History',
  'button.locationHistory': 'Location History',
  'button.areaHistory': 'Area History',

  // END BUTTON

  // START LABEL
  'label.superAdmin': 'Super Admin',
  'label.hqUser': 'HQ User',
  'label.areaUser': 'Area User',
  'label.locationUser': 'Location User',
  'label.managerDetail': 'Manager Detail',
  'label.rememberMe': 'Remember me',
  'label.forgotPassword': 'Forgot your password?',
  'label.phoneNumber': 'Phone Number',
  'label.contactNumber': 'Contact Number',
  'label.firstName': 'First Name',
  'label.lastName': 'Last Name',
  'label.name': 'Name',
  'label.displayName': 'Display Name',
  'label.email': 'Email',
  'label.workEmail': 'Work Email',
  'label.notification': 'Notification',
  'label.address': 'Address',
  'label.gender': 'Gender',
  'label.dateOfBirth': 'Date Of Birth',
  'label.referralCode': 'Referral Code',
  'label.foodHygiene': 'Food Hygiene',
  'label.foodHygieneCert': 'Food Hygiene Cert',
  'label.sunOfJODBadge': 'Sun of JOD <0 /> Badge',
  'label.newJobNotifications': 'New Job <0 /> Notifications',
  'label.unsubscribeNews': 'Unsubscribe <0 /> News',
  'label.hqCompany': 'HQ Company',
  'label.deletedAccount': 'Deleted Account',
  'label.language': 'Language',
  'label.download': 'Download',
  'label.workHistory': 'Work History',
  'label.currentOccupation': 'Current Occupation',
  'label.job': 'Job',
  'label.jobs': 'Jobs',
  'label.activeJobs': 'Active Jobs',
  'label.openJobs': 'Open Jobs',
  'label.completedJobs': 'Completed Jobs',
  'label.currentMonth': '(Current Month)',
  'label.todayHired': 'Today Hired',
  'label.totalHired': 'Total Hired',
  'label.totalCredits': 'Total Credits',
  'label.numberOfHiredStaff': 'Number of Hired Staffs',
  'label.hqAvailableJodCredits': 'HQ Available <0/> JOD Credits ',
  'label.locationJodCredits': 'Location <0/> JOD Credits',
  'label.consumedCredits': 'Consumed Credits',
  'label.generalRating': 'General Rating',
  'label.serviceContract': 'Service Contract',
  'label.locationName': 'Location Name',
  'label.availableCredits': 'Available Credits',
  'label.areaManager': 'Area Manager',
  'label.locationManagers': 'Location Managers',
  'label.areaManagers': 'Area Managers',
  'label.function': 'Function',
  'label.min': 'Min',
  'label.max': 'Max',
  'label.recent': 'Recently Added',
  'label.recentAddCompanies': 'Recently Added Companies',
  'label.enableCompanies': 'Enable Companies',
  'label.disableCompanies': 'Disable Companies',
  'label.partnerCompanies': 'Partner Companies',
  'label.activeCompanies': 'Active Companies',
  'label.companyName': 'Company Name',
  'label.companyAddress': 'Company Address',
  'label.companyStatus': `Company’s Status`,
  'label.businessRegistration': 'Business Registration',
  'label.minJODCreditLimit': 'Minimum JOD Credit Limit',
  'label.jobTypeStatus': `Job Type’s Status`,
  'label.numberOfAvailablePositions': 'Number of Available Positions',
  'label.numberOfAvailableCompanies': 'Number of Available Companies',
  'label.minimumAvailablePositions': 'Minimum Available Positions',
  'label.maximumAvailablePositions': 'Maximum Available Positions',
  'label.minimumAvailableCompanies': 'Minimum Available Companies',
  'label.maximumAvailableCompanies': 'Maximum Available Companies',
  'label.minAvailablePositions': 'Min Available Positions',
  'label.maxAvailablePositions': 'Max Available Positions',
  'label.minAvailableCompanies': 'Min Available Companies',
  'label.maxAvailableCompanies': 'Max Available Companies',
  'label.generalDescription': 'General Description',
  'label.jobDescription': 'Job Description',
  'label.comment': 'Comment',
  'label.jobTitle': 'Job Title',
  'label.hqManager': 'HQ Manager',
  'label.hqMangerName': 'HQ Manager Name',
  'label.contactInformation': 'Contact Information',
  'label.company': 'Company',
  'label.recentAddHQUsers': 'Recently Added HQ Users',
  'label.companyHQMainContactUserDetails': 'Company HQ Main Contact User Details',
  'label.disableHQUsers': 'Disable HQ Users',
  'label.enableHQUsers': 'Enable HQ Users',
  'label.hqUserStatus': `HQ User’s Status`,
  'label.totalNumberOfHQUsers': 'Total Number of <0 /> HQ Users',
  'label.numberOfActiveHQUsers': 'Number of <0 /> Active HQ Users',
  'label.numberOfDisableHQUsers': 'Number of <0 /> Disable HQ Users',
  'label.recentAddJobTypes': 'Recently Added Job Types',
  'label.disableJobTypes': 'Disable Job Types',
  'label.enableJobTypes': 'Enable Job Types',
  'label.enabledCompanies': 'Enabled companies',
  'label.disabledCompanies': 'Disabled companies',
  'label.recentAdded': 'Recent Added',
  'label.totalNumberOfLocations': 'Total Number of <0/> Locations',
  'label.locationStatus': `Location’s Status`,
  'label.minimumAvailableCredits': 'Minimum Available Credits',
  'label.maximumAvailableCredits': 'Maximum Available Credits',
  'label.minimumConsumedCredits': 'Minimum Consumed Credits',
  'label.maximumConsumedCredits': 'Maximum Consumed Credits',
  'label.minAvailableCredits': 'Min Available Credits',
  'label.maxAvailableCredits': 'Max Available Credits',
  'label.minConsumedCredits': 'Min Consumed Credits',
  'label.maxConsumedCredits': 'Max Consumed Credits',
  'label.minAssignedCredits': 'Min Assigned Credits',
  'label.maxAssignedCredits': 'Max Assigned Credits',
  'label.minAvailableConsumed': 'Min Available Consumed',
  'label.maxAvailableConsumed': 'Max Available Consumed',
  'label.minimumAvailableConsumed': 'Minimum Available Consumed',
  'label.maximumAvailableConsumed': 'Maximum Available Consumed',
  'label.locationAddress': 'Location Address',
  'label.jobApprovalRequired': 'Job Approval <0/> Required',
  'label.locationManagerCanCreateJob': 'Location Managers <0/> Can Also Create Jobs',
  'label.createFromTemplate': 'Create From <0/> Template Only',
  'label.creditDeductionAtLocationLevel': 'JOD Credit Deduction <0/> at Location Level',
  'label.autoJobPosting': 'Auto Job <0/> Posting',
  'label.date': 'Date',
  'label.inProgress': 'In Progress',
  'label.assignedCredits': 'Assigned Credits',
  'label.applicantName': `Applicant Name`,
  'label.applicantStatus': `Applicant's Status`,
  'label.applicantIsFeedback': `Applicant’s Feedback`,
  'label.applicantIsRating': `Applicant’s Rating`,
  'label.identityStatus': 'Identity Status',
  'label.managerFeedback': `Manager’s Feedback`,
  'label.managerRating': `Manager’s Rating`,
  'label.minimumRating': 'Minimum Rating',
  'label.maximumRating': 'Maximum Rating',
  'label.applicantsWithAmountJobs': 'Applicants with Job Amount',
  'label.availableBreakLineCredits': 'Available <0/> Credits',
  'label.consumedBreakLineCredits': 'Consumed <0/> Credit',
  'label.disableLocations': 'Disable Locations',
  'label.enableLocations': 'Enable Locations',
  'label.maskAsValid': 'Mark as Valid',
  'label.maskAsInvalid': 'Mark as Invalid',
  'label.userAccountID': 'User Account ID',
  'label.verifyCode': 'Verify Code',
  'label.school': 'School',
  'label.averageRating': 'Average rating',
  'label.totalWorkingHours_breakLine': 'Total working <0 /> hours',
  'label.totalCompletedJobs_breakLine': 'Total completed <0 /> jobs',
  'label.totalCompletedJobs': 'Total completed jobs',
  'label.totalNumberOfApplicants': 'Total Number of <0 /> Applicants',
  'label.numberOfActiveApplicants': 'Number of <0 /> Active Applicants',
  'label.numberOfDisableApplicants': 'Number of <0 /> Disable Applicants',
  'label.recentAddedApplicants': 'Recently Added Applicants',
  'label.disableApplicants': 'Disable Applicants',
  'label.enableApplicants': 'Enable Applicants',
  'label.minRating': 'Min Rating',
  'label.maxRating': 'Max Rating',
  'label.applicantWithNumberOfJobs': 'Applicant with Number of Jobs',
  'label.manager': 'Manager',
  'label.managerType': 'Manager Type',
  'label.locations': 'Locations',
  'label.totalNoAreaManagers': 'Total Number of <0/> Area Managers',
  'label.totalNoLocationManagers': 'Total Number of <0/> Location Managers',
  'label.managerStatus': `Manager’s Status`,
  'label.location': `Location`,
  'label.institutionName': 'Institution Name',
  'label.instituteName': 'Institute Name',
  'label.description': 'Description',
  'label.educationInstituteStatus': `Education Institute's Status`,
  'label.totalNumberOfEducationalInstitutes': 'Total Number of <0 /> Educational Institutes',
  'label.recentAddedEducationInstitutes': 'Recently Added Educational Institutes',
  'label.disableEducationalInstitutes': 'Disable Educational Institutes',
  'label.enableEducationalInstitutes': 'Enable Educational Institutes',
  'label.templateName': 'Template Name',
  'label.jobStart': 'Job Start',
  'label.jobEnd': 'Job End',
  // TODO: new translation (2)
  'label.slotStart': 'Slot Start',
  'label.slotEnd': 'Slot End',
  'label.wage': 'Wage',
  'label.applicationNo': 'Application No.',
  'label.hiredNo': 'Hire No.',
  'label.totalNumberOfJobs': 'Total Number of <0/> Jobs',
  'label.jobWithApplicants': 'Job with Applicants',
  'label.jobStart&JobEnd': 'Job Start & Job End',
  'label.minimumWage': 'Minimum Wage',
  'label.maximumWage': 'Maximum Wage',
  'label.from': 'From',
  'label.to': 'To',
  'label.include': 'Include',
  'label.exclude': 'Exclude',
  'label.totalNumberOfJobTemplates': 'Total Number of <0/> Job Templates',
  'label.jobTemplateStatus': 'Job Templates’s Status',
  'label.jobType': 'Job Type',
  'label.hourlyRate': 'Hourly Rate',
  'label.specialInstructions': 'Special Instructions',
  'label.specialInstructions_breakLine': 'Special <0/> Instructions',
  'label.createdDate': 'Created Date',
  'label.timeIn': 'Time In',
  'label.timeOut': 'Time Out',
  'label.totalHours': 'Total Hours',
  'label.totalWages': 'Total Wages',
  'label.status': 'Status',
  'label.jobStatus': `Job Status`,
  'label.jobIsStatus': `Job's Status`,
  'label.createMonth': 'Create Month',
  'label.assigningAmount': 'Assigning Amount',
  'label.amount': 'Amount',
  'label.feedback': 'Feedback',
  'label.assigningDate': 'Assigning Date',
  'label.startDate': 'Start Date',
  'label.endDate': 'End Date',
  'label.minimumAssignAmount': 'Minimum Assign Amount',
  'label.maximumAssignAmount': 'Maximum Assign Amount',
  'label.minimumAssignedAmount': 'Minimum Assigned Amount',
  'label.maximumAssignedAmount': 'Maximum Assigned Amount',
  'label.selectAll': 'Select all',

  'label.jodID': 'Job ID',
  'label.slotID': 'Slot ID',
  'label.clockIn': 'Clock In',
  'label.clockOut': 'Clock Out',
  'label.netPay': 'Net Pay',
  'label.detail': 'Detail',
  'label.rating': 'Rating',
  'label.breakTime': 'Break Time',
  'label.deductedCredits': 'Deducted credits',
  'label.jodCredit': 'JOD Credit',
  'label.jobHistory': 'Job History',
  'label.locationCredit': 'Location Credit',
  'label.processedDate': 'Processed Date',
  'label.jodAllowance': 'Allowance',
  'label.jodAllowanceDeduction': 'Allowance Deduction',
  'label.jodAllowanceDeductionLabel': 'JOD Allowance',
  'label.companyAllowanceDeductionLabel': 'Company Allowance',
  'label.bankPaid': 'Bank Paid',
  'label.bankName': 'Bank Name',
  'label.bankHolderName': 'Bank Holder Name',
  'label.bankAccountNumber': 'Bank Account Number',
  'label.paymentStatus': `Payment's Status`,
  'label.recentAddedPayments': 'Recently Added Payments',
  'label.jobDetail': 'Job Detail',
  'label.jobDetails': 'Job Details',
  'label.wagePerHour': 'Wage Per Hour',
  'label.jodAmendedDetails': 'JOD Amended Details',
  'label.noAdjustmentMade': 'No adjustment made',
  'label.completedByAdmin': 'Completed by Admin',
  'label.unavailable': 'Unavailable',

  'label.fullName': 'Full Name',
  'label.totalJodCredits': 'Total JOD Credits',
  'label.insuranceAmount': 'Insurance Amount',
  'label.netPayableAmount': 'Net Payable Amount',
  'label.accountHolder': 'Account Holder',
  'label.adjustedJODCredit': 'Adjusted JOD Credit',
  'label.adjustedJodCredits': 'Adjusted JOD Credits',
  'label.adjustedAt': 'Adjusted at',

  'label.totalNumberOfPayments': 'Total Number of <0 /> Payments',
  'label.totalNumberOfProcessedPayments': 'Total Number of <0 /> Processed Payments',
  'label.totalNumberOfUnpaidPayments': 'Total Number of <0 /> Unpaid Payments',

  'label.applicantID': 'Applicant ID',
  'label.applicantFirstName': 'Applicant First Name',
  'label.applicantLastName': 'Applicant Last Name',
  'label.applicantNRIC/FICNumber': 'Applicant NRIC/FIN Number',
  'label.applicantEmail': 'Applicant Email',
  'label.applicantContactNumber': 'Applicant Contact Number',
  'label.applicantGender': 'Applicant Gender',
  'label.applicantDoB': 'Applicant DoB',
  'label.yearOld': '{{age}} years old',
  'label.applicantAge': 'Applicant Age',
  'label.applicantAddress': 'Applicant Address',
  'label.applicantRating': 'Applicant Rating',
  'label.applicantFeedback': 'Applicant Feedback',
  'label.applicantEducationalInstitution': 'Applicant Educational Institution',
  'label.applicantBankName': 'Applicant Bank Name',
  'label.applicantV1BankName': 'Applicant V1 Bank Name',
  'label.applicantBankAccountName': 'Applicant Bank Account Name',
  'label.applicantBankAccountNumber': 'Applicant Bank Account Number',
  'label.jobStartDateTime': 'Job Start Datetime',
  'label.jobEndDateTime': 'Job End Datetime',
  'label.totalExpectedHours': 'Total Expected Hours',
  'label.totalExpectedWages': 'Total Expected Wages',
  'label.jobCreatedDateTime': 'Job Created Datetime',
  'label.daysOfJobCreatedAndJobStart': 'Job Created vs Job Start (days)',
  'label.hoursOfJobCreatedAndJobStart': 'Job Created vs Job Start (hours)',
  'label.applicantAppliedDateTime': 'Applicant Applied Datetime',
  'label.daysOfJobCreatedAndApplicantApplied': 'Job Created vs Applicant Applied (days)',
  'label.hoursOfJobCreatedAndApplicantApplied': 'Job Created vs Applicant Applied (hours)',
  'label.applicantHiredDateTime': 'Applicant Hired Datetime',
  'label.daysOfApplicantAppliedAndApplicantHired': 'Applicant Applied vs Applicant Hired (days)',
  'label.hoursOfApplicantAppliedAndApplicantHired': 'Applicant Applied vs Applicant Hired (hours)',
  'label.jobApplicantStatus': 'Job Applicant Status',
  'label.slotApplicantStatus': 'Slot Applicant Status',
  'label.slotStartDateTime': 'Slot Start Datetime',
  'label.slotEndDateTime': 'Slot End Datetime',
  'label.applicantClockIn': 'Applicant Clock-in',
  'label.applicantClockOut': 'Applicant Clock-out',
  'label.applicantCancelledJob': 'Applicant Cancelled Job',
  'label.applicantCancellationFeedback': 'Applicant Cancellation Feedback',
  'label.ack1Done': 'ACK1 Done',
  'label.ack2Done': 'ACK2 Done',
  'label.jobCompleted': 'Job Completed',
  'label.hiringManagerID': 'Hiring Manager ID',
  'label.hiringManagerFirstName': 'Hiring Manager First Name',
  'label.hiringManagerLastName': 'Hiring Manager Last Name',
  'label.hiringMangerContactNumber': 'Hiring Manager Contact Number',
  'label.hiringManagerEmail': 'Hiring Manager Email',
  'label.hiringManagerRating': 'Hiring Manager Rating',
  'label.hiringManagerFeedback': 'Hiring Manager Feedback',
  'label.jobApplicantIsStatus': `Job Applicant's Status`,
  'label.slotApplicantIsStatus': `Slot Applicant's Status`,
  'label.totalWorkingHours': 'Total Working Hours',
  'label.jobId': 'Job ID',
  'label.totalDays': 'Total Days',
  'label.bankTransfer': 'Bank Transfer',
  'label.originalJobStart': 'Original Job Start',
  'label.originalJobEnd': 'Original Job End',
  'label.actualClockIn': 'Actual Clock In',
  'label.actualClockOut': 'Actual Clock Out',
  'label.dob': 'DOB',
  'label.jodCompletedJobs': 'JOD Completed Job',
  'label.view': 'View',
  'label.select': 'Select',

  'label.locationManager': 'Location Manager',
  'label.earningPerHours': 'Earning Per Hour',
  'label.workingDay': 'Choose Required Working Days',
  'label.dateAndTime': 'Date and Time',

  'label.content': 'Content',
  'label.value': 'Value',
  'label.push': 'Push',
  'label.sms': 'SMS',
  'label.id': 'ID',
  'label.enabled': 'Enabled',

  'label.internalPermissions': 'Internal Permissions',
  'label.partnerPermissions': 'Partner Permissions',
  'label.permission': 'Permission',
  'label.roleName': 'Role Name',
  'label.userName': 'User Name',
  'label.role': 'Role',
  'label.userDetails': 'User Details',
  'label.internalRoles': 'Internal Roles',
  'label.partnerRoles': 'Partner Roles',

  'label.changeWagePerHour': 'Change Wage Per Hour',

  'label.password': 'Password',
  'label.oldPassword': 'Old Password',
  'label.newPassword': 'New Password',
  'label.confirmPassword': 'Confirm Password',

  'label.credit': 'Credit',
  'label.jodCredits': 'Jod Credits',
  'label.employeeName': 'Employee Name',

  'label.consumedCreditsPreviousMonth': 'Consumed Credits <0 /> (Previous Month)',
  'label.consumedCreditsCurrentMonth': 'Consumed Credits <0 /> (Current Month)',

  'label.jodCreditAvailableWithHeadquarter': 'HQ Unassigned Credit',
  'label.test': 'Test',

  'label.clockIn&ClockOut': 'Clock In & Clock Out',
  'label.minimumTotalJodCredit': 'Minimum Total Jod Credit',
  'label.maximumTotalJodCredit': 'Maximum Total Jod Credit',
  'label.jodCreditDeductionLevel': 'JOD Credit Deduction Level ',
  'label.mealBreakTime': 'Meal Break Time',
  'label.availableCreditBeforeDeduction': 'Available Credit Before Deduction',
  'label.availableCreditAfterDeduction': 'Available Credit After Deduction',

  'label.assignBadge': 'Assign Badge',
  'label.badge': 'Badge',

  'label.allCompanies': 'All Companies',
  'label.futurePayment': 'Future Payment',
  'label.allFuturePayments': 'All Future Payments',
  'label.paymentDetails': 'Payment Details',
  'label.paymentSummary': 'Payment Summary',

  'label.unprocessedCredits': 'Unprocessed Credits',
  'label.processedCredits': 'Processed Credits',

  'label.recentAddLocations': 'Recently added Locations',
  'label.locationStatus.': `Location's Status`,

  'label.noHistory': 'No History',
  'label.noExperience': 'No Experience',
  'label.candidateName': 'Candidate Name',
  'label.applyDate': 'Apply Date',
  'label.jobStartAndJobEnd': 'Job Start - Job End',
  'label.paymentProcessed': 'Payment Processed',
  'label.notProcessed': 'Not processed',

  'label.clickCancelSorting': 'Click to cancel sorting',
  'label.clickToSortAsc': 'Click to sort ascending',
  'label.clickToSortDesc': 'Click to sort descending',

  'label.unknown': 'Unknown',
  'label.receivedCredits': 'Received Credits',
  'label.feedbackFromApplicant': 'Feedback from Applicant',
  'label.feedbackFromManager': 'Feedback from Manager',
  'label.ratingFromApplicant': 'Rating from Applicant',
  'label.ratingFromManager': 'Rating from Manager',
  'label.applicantInformation': 'Applicant Information',

  'label.aptusTermsOfUseAndConditionsForCustomers': 'APTUS Terms Of Use & Conditions for Customers',
  'label.mon': 'Mon',
  'label.tue': 'Tue',
  'label.wed': 'Wed',
  'label.thur': 'Thur',
  'label.fri': 'Fri',
  'label.sat': 'Sat',
  'label.sun': 'Sun',
  'label.all': 'All',
  'label.asc': 'ASC',
  'label.desc': 'DESC',
  'label.nric': 'NRIC/FIN',
  'label.lessThan1Years': 'Less than 1 year',
  'label.between1To2Years': 'Between 1 to 2 years',
  'label.moreThan2Years': 'More than 2 years',
  'label.hour': 'hour',
  'label.hours': 'hours',
  'label.minute': 'min',
  'label.minutes': 'mins',
  'label.checkboxSendMailToApplicant': 'Send email to applicant',
  'label.certificates': 'Certificates',
  'label.noCertificates': 'No Certificates',
  'label.age': 'Age',
  'label.locationAverageRating': `Location's average rating`,

  'label.transactionDate': 'Transaction Date',
  'label.debit': 'Debit',
  'label.receiveSMSNotifications': 'Receive SMS Notifications',

  // END LABEL

  // START PLACEHOLDER
  'placeholder.email': 'Email',
  'placeholder.password': 'Password',
  'placeholder.uploadServiceContract': 'Upload contract for service *',
  'placeholder.uploadedServiceContract': 'Contract for service uploaded',
  'placeholder.uploadBrandBanner': 'Upload brand banner (optional)',
  'placeholder.uploadedBanner': 'Brand banner uploaded',
  'placeholder.minExpectedCredit': 'Minimum expected amount of credit',
  'placeholder.enterKeyword': 'Enter your keyword',
  'placeholder.enterJobTitle': 'Enter your job title',
  'placeholder.credit': '0.00',
  'placeholder.upload': 'Upload',
  'placeholder.uploaded': 'Uploaded',
  'placeholder.uploadLogo': 'Upload logo',
  'placeholder.uploadBanner': 'Upload banner',
  'placeholder.uploadProfilePicture': 'Upload profile picture',
  'placeholder.firstName': 'Please enter first name',
  'placeholder.lastName': 'Please enter last name',
  'placeholder.jobTitle': 'Please enter job title',
  'placeholder.contactNumber': 'Please enter contact number',
  'placeholder.workEmail': 'Please enter work email',
  'placeholder.creditAmount': 'Credit amount',
  'placeholder.comment': 'Comment',
  'placeholder.locationName': `Please enter location’s name`,
  'placeholder.locationAddress': `Please enter location’s address`,
  'placeholder.areaManager': `Select Area Manager`,
  'placeholder.managerType': 'Please select manager type',
  'placeholder.location': 'Please select a location',
  'placeholder.locations': 'Please select locations',
  'placeholder.applicantFirstName': `Applicant’s first name`,
  'placeholder.applicantLastName': `Applicant’s last name`,
  'placeholder.applicantDisplayName': `Applicant’s display name`,
  'placeholder.applicantDateOfBirth': `Applicant's date of birth`,
  'placeholder.applicantNRIC/FIN': 'Applicant’s NRIC/FIN',
  'placeholder.applicantContactNumber': 'Applicant’s contact number',
  'placeholder.applicantEmail': 'Applicant’s email',
  'placeholder.applIcantSelectSchool': 'Select applicant’s school',
  'placeholder.applicantReferralCode': 'Applicant’s referral code',
  'placeholder.applicantSelectHQCompany': 'Select applicant’s HQ company',
  'placeholder.enterRating': 'Enter rating',
  'placeholder.selectStatus': 'Select status',
  'placeholder.selectDate': 'Select date',
  'placeholder.selectLocations': 'Select locations',
  'placeholder.selectMonth': 'Select a month',
  'placeholder.selectCompany': 'Select company',

  'placeholder.status': 'Select status',
  'placeholder.startDate': 'Start date',
  'placeholder.endDate': 'End date',
  'placeholder.enterTemplateName': 'Please enter job template',
  'placeholder.jobType': 'Please select a job type',
  'placeholder.noDateSelected': 'No date selected',
  'placeholder.pleaseSelectDate': 'Please select a date.',
  'placeholder.jobDescription': 'Please enter job description',
  'placeholder.specialInstructions': 'Please enter special instructions',
  'placeholder.hourlyRate': 'Please enter hourly rate',
  'placeholder.pleaseEnterBankHolderName': 'Please enter bank holder name',
  'placeholder.pleaseEnterBankAccountNumber': 'Please enter bank account number',

  'placeholder.selectLocation': 'Select a location',
  'placeholder.feedback': 'Please enter a comment',
  'placeholder.noAppliedApplicant': 'No applied applicants',

  'placeholder.selectTemplateName': 'Please select a template',
  'placeholder.selectLocationManager': 'Please select a manager',
  'placeholder.pleaseSelectLanguage': 'Please select language.',

  'placeholder.currentWagePerHour': 'Current Wage Per Hour',
  'placeholder.newWagePerHour': 'New Wage Per Hour',
  'placeholder.allLocations': 'All Locations',
  'placeholder.allJobs': 'All Jobs',
  'placeholder.allJobStatus': 'All JobStatus',

  'placeholder.pleaseSelectAnOption': 'Please select an option',
  'placeholder.pleaseSelectAColumn': 'Please select a column',
  'placeholder.pleaseEnterAssigningAmount': 'Please enter assigning amount',

  // END PLACEHOLDER

  // START TITLE
  'title.dashboard': 'Dashboard',
  'title.editProfile': 'EDIT PROFILE',
  'title.companies': 'Companies',
  'title.company': 'Company',
  'title.newCompany': 'New Company',
  'title.editCompany': 'Edit Company',
  'title.listOfCompanies': 'List of Companies',
  'title.listOfJobTypes': 'List of Job Types',
  'title.jobTypes': 'Job Types',
  'title.jobType': 'Job Type',
  'title.newJobType': 'New Job Type',
  'title.editJobType': 'Edit Job Type',
  'title.hqUsers': 'HQ Users',
  'title.listOfHQUsers': 'List of HQ Users',
  'title.newHQUsers': 'HQ Manager Registration',
  'title.editHQUsers': 'Edit HQ Manager',
  'title.applicants': 'Applicants',
  'title.listOfApplicants': 'List of Applicants',
  'title.newApplicant': 'New Applicant',
  'title.editApplicant': 'Edit Applicant',
  'title.educationalInstitutes': 'Educational Institutes',
  'title.educationalInstitutesHasTrans': 'Educational <0 /> Institutes',
  'title.listOfEducationInstitutes': 'List of Educational Institutes',
  'title.newEducationalInstitute': 'New Educational Institute',
  'title.editEducationalInstitute': 'EDIT Educational Institute',
  'title.locations': 'Locations',
  'title.listOfLocations': 'List of Locations',
  'title.locationFeedback': 'Location Feedback',
  'title.billingRecords': 'Billing Records',
  'title.jodCredits': 'JOD Credits',
  'title.assignCredit': 'Assign Credit',
  'title.creditHistory': 'Credit History',
  'title.debitCreditHistory': 'Debit Credit History',
  'title.payments': 'Payments',
  'title.jobSummary': 'Job Summary',
  'title.jobPayments': 'Job Payments',
  'title.futurePayments': 'Future Payments',
  'title.report': 'Report',
  'title.usersAndPermissions': 'Users and Permissions',
  'title.users': 'Users',
  'title.superHqUsers': 'Super HQ Users',
  'title.listOfInternalUsers': 'List of Internal Users',
  'title.listOfSuperHqUsers': 'List of Super HQ Users',
  'title.newInternalUser': 'New Internal User',
  'title.newSuperHqUser': 'New Super HQ User',
  'title.editInternalUser': 'Edit Internal User',
  'title.editSuperHqUser': 'Edit Super HQ User',
  'title.roles': 'Roles',
  'title.listOfRoles': 'List of Roles',
  'title.newRole': 'New Role',
  'title.newInternalRole': 'New Internal Role',
  'title.editRole': 'Edit Role',
  'title.permissions': 'Permissions',
  'title.configuration': 'Configuration',
  'title.notifications': 'Notifications',
  'title.notificationConfigurations': 'Notification Configuration',
  'title.other': 'Other',
  'title.otherConfigurations': 'Other Configurations',
  'title.badgeConfigurations': 'Badge Configurations',
  'title.jodBadges': 'JOD Badges',
  'title.jodBadgesConfiguration': 'JOD Badges Configurations',
  'title.locationList': 'Location List',
  'title.addNewLocation': 'Add New Location',
  'title.managers': 'Managers',
  'title.managerList': 'Manager List',
  'title.addNewManager': 'Add New Manager',
  'title.locationProfile': 'Location Profile',
  'title.jobTemplates': 'Job Templates',
  'title.jobTemplateList': 'Job Template List',
  'title.addNewJobTemplate': 'Add New Job Template',
  'title.jobPosting': 'Job Posting',
  'title.allJobPosting': 'All Job Posting',
  'title.createNewJob': 'Create New Job',
  'title.manageJobs': 'Manage Jobs',
  'title.updateJobs': 'Update Jobs',
  'title.cancelJobs': 'Cancel Jobs',
  'title.jobOverview': 'Job Overview',
  'title.hiringOverview': 'Hiring Overview',
  'title.creditOverview': 'Credit Overview',
  'title.ratingOverview': 'Rating Overview',
  'title.addNewAreaManager': 'Add New Area Manager',
  'title.newLocation': 'New Location',
  'title.editLocation': 'Edit Location',
  'title.assignJodCredit': 'Assign Jod Credit',
  'title.listOfManagers': 'List of Managers',
  'title.managerRegistration': 'Manager Registration',
  'title.editLocationManager': 'Edit Location Manager',
  'title.editAreaManager': 'Edit Area Manager',
  'title.editManager': 'Edit Manager',
  'title.addJobTemplate': 'Add Job Template',
  'title.editJobTemplate': 'Edit Job Template',
  'title.jobHistory': 'Job History',
  'title.candidateProfile': 'Candidate Profile',
  'title.listOfAllCandidates': 'List of All Candidates',
  'title.candidateOfJob': 'Candidate of Job',
  'title.candidatesOfNamedJob': 'CANDIDATES OF {{job}} JOB',
  'title.candidatesOfJob': 'Candidates Of Job',
  'title.appConfig': 'App Configurations',

  'title.qrCode': 'QR Code',
  'title.jobInformation': 'Job Information',
  'title.jobNote': 'Job Note',
  'title.workerInformation': 'Worker Information',
  'title.jobEdit': 'Edit Job',
  'title.copyJob': 'Copy Job',

  'title.howToUse': 'How To Use',
  'title.howToPostJobs': 'How To Post Jobs',
  'title.howToMakeSelections': 'How To Make Selections',
  'title.howToClockInAndOut': 'How To Clock In And Out',
  'title.currentAvailableCredit': 'Current Available Credit',
  'title.redirecting': 'Redirecting',
  'title.loadPage': 'load page...',
  'title.unassignedCredit': 'Unassigned Credit',
  'title.hqLevel': 'HQ level',
  'title.uploadCertification': 'Upload Certification',
  'title.hiringManager': 'Hiring Manager',
  'title.superHqSelectCompany': 'Select Company (HQ)',
  'title.404PageNotFound': '404 Page Not Found',

  'title.schedule': 'Schedule',
  'title.schedule.job': 'Jobs',
  'title.schedule.job.template': 'Templates',
  'title.schedule.job.paymentrules': 'Payment Rules',
  'title.workforce': 'Workforce',
  'title.workforce.manager': 'Manager',
  'title.workforce.worker': 'Worker',
  'title.outlet': 'Outlet',
  'title.payroll': 'Payroll',
  'title.admin': 'Admin',
  'title.admin.account.settings': 'Account Settings',
  // END TITLE

  // START MESSAGE
  'message.changePassword': 'Change Password',
  'message.confirmPasswordMustMatchPassword': 'Password must match',
  'message.totalNumberOf': 'Total Number of',
  'message.numberOf': 'Number of',
  'message.actionFailed': 'Action Failed. Please try again!',
  'message.somethingWentWrong': 'Something went wrong. Please try again!',
  'message.somethingWentWrong1': 'Something went wrong!',
  'message.emailOrPasswordIncorrect': 'Email or password is incorrect',
  'message.enableCompany': 'You chose to enable this company.',
  'message.disableCompany': 'You chose to disable this company.',
  'message.disableAccount': 'You chose to disable this account.',
  'message.enableAccount': 'You chose to enable this account.',
  'message.validateMinLessThanMaxCredits':
    'The minimum available credits must be less than maximum available credits.',
  'message.validateMaxGreaterThanMinCredits':
    'The maximum available credits must be greater than minimum available credits.',
  'message.validateOnlyIntegerNumber': 'Only allow numbers to be integers.',
  'message.pleaseEnterContactNumber': 'Please enter contact number.',
  'message.contactNumberMustBeNumber': 'The contact number must be number',
  'message.contactNumberMustBe8Digits': 'The contact number must be 8 digits.',
  'message.contactNumberMustBe9Digits': 'The contact number must be 9 digits.',
  'message.contactNumberMustBe10Digits': 'The contact number must be 10 digits.',
  'message.contactNumber9Or10': 'The contact number must be 9 or 10 digits.',
  'message.minLessThanMaxConsumeCredit':
    'The minimum consumed credits must be less than maximum consumed credits.',
  'message.maxGreaterThanMaxConsumeCredit':
    'The maximum consumed credits must be greater than minimum consumed credits.',
  'message.validateNotBeGreaterThan255Characters':
    'The title must not be greater than 255 characters.',
  'message.validateTypeFileCompanyAvatar':
    'The company avatar filename must be a file of type: {{files}}.',
  'message.validateTypeFileCompanyLogo':
    'The company logo filename must be a file of type: {{files}}.',
  'message.validateTypeFileJobTypeLogo':
    'The job type logo filename must be a file of type: {{files}}.',
  'message.validateTypeFileContractService':
    'The contract service filename must be a file of type: {{files}}.',
  'message.pleaseUploadServiceContract': 'Please upload contract for service.',
  'message.validateTypeFileCompanyBanner':
    'The company banner filename must be a file of type: {{files}}.',
  'message.registrationCompleted': 'Your registration is completed.',
  'message.changeCompleted': 'Your change is completed.',
  'message.wantToSubmit': 'Do you want to submit?',
  'message.wantToSave': 'Do you want to save?',
  'message.wantToContinue': 'Do you want to continue?',
  'message.pleaseSelectCompany': 'Please select a company',
  'message.selectCompany': 'Select a company',
  'message.pleaseEnterCompanyName': 'Please enter company’s name',
  'message.pleaseEnterCompanyAddress': 'Please enter company’s address',
  'message.pleaseEnterBusinessCompany': 'Please enter company’s business number',
  'message.pleaseEnterMinJODCreditLimit': 'Please enter valid minium JOD credit limit',
  'message.enableJobType': 'You chose to enable this job type.',
  'message.disableJobType': 'You chose to disable this job type.',
  'message.pleaseEnterJobTitle': 'Please enter job title',
  'message.pleaseEnterJobDescription': 'Please enter main job description',
  'message.pleaseEnterComment': 'Please enter comment',
  'message.commentLengthCannotExceed255Characters': 'Comment length cannot exceed 255 characters',
  'message.minAvailablePositionsLargerThanOrEqualTo0':
    'The minimum available positions must be larger than or equal to 0.',
  'message.maxAvailablePositionsLargerThanOrEqualTo0':
    'The maximum available positions must be larger than or equal to 0.',
  'message.minAvailableCompaniesLargerThanOrEqualTo0':
    'The minimum available companies must be larger than or equal to 0.',
  'message.maxAvailableCompaniesLargerThanOrEqualTo0':
    'The maximum available companies must be larger than or equal to 0.',
  'message.minLessThanMaxAvailablePositions':
    'The minimum available positions must be less than maximum available positions.',
  'message.maxGreaterThanMinAvailablePositions':
    'The maximum available positions must be greater than minimum available positions.',
  'message.minLessThanMaxAvailableCompanies':
    'The minimum available companies must be less than maximum available companies.',
  'message.maxGreaterThanMinAvailableCompanies':
    'The maximum available companies must be greater than minimum available companies.',
  'message.pleaseEnterFirstName': 'Please enter first name',
  'message.pleaseEnterLastName': 'Please enter last name',
  'message.pleaseEnterWorkEmail': 'Please enter work email',
  'message.workEmailMustBeValidEmailAddress': 'The work email must be a valid email address.',
  'message.firstNameHasMaximum100Characters': 'The first name has a maximum of 100 characters.',
  'message.firstNameHasMinimum1Character': 'The first name has a minimum of 1 character.',
  'message.firstNameAlphabetsOnlyNoNumericNoSpecialCharacters': `The first name must be alphabets only, no numeric, no special characters (except ,-/'@).`,
  'message.lastNameHasMaximum100Characters': 'The last name has a maximum of 100 characters.',
  'message.lastNameHasMinimum1Character': 'The last name has a minimum of 1 character.',
  'message.lastNameAlphabetsOnlyNoNumericNoSpecialCharacters': `The last name must be alphabets only, no numeric, no special characters (except ,-/'@).`,
  'message.enableLocation': 'You chose to enable this location.',
  'message.disableLocation': 'You chose to disable this location.',
  'message.noData': 'No data available',
  'message.locationNotFound': 'Location not found',
  'message.onlyAllowTwoDecimalPlace': 'Only allow decimals with a maximum of 2 decimal places.',
  'message.commentRequired': 'Please enter comment.',
  'message.creditAmountRequired': 'Please enter valid credit amount.',
  'message.locationLogoMustBe': 'The location logo filename must be a file of type: {{files}}.',
  'message.locationNameRequired': `Please enter location’s name.`,
  'message.locationAddressRequired': `Please enter location’s address.`,
  'message.minJODCreditRequired': 'Please enter valid minimum JOD credit limit.',
  'message.firstNameRequired': 'Please enter first name.',
  'message.lastNameRequired': 'Please enter last name.',
  'message.jobTitleRequired': 'Please enter job title.',
  'message.workEmailRequired': 'Please enter work email.',
  'message.enableManager': 'You chose to enable this manager.',
  'message.disableManager': 'You chose to disable this manager.',
  'message.managerTypeRequired': 'Please select manager type.',
  'message.pleaseEnterApplicantFirstName': 'Please enter applicant’s first name',
  'message.pleaseEnterApplicantLastName': 'Please enter applicant’s last name',
  'message.pleaseEnterReasonHere': 'Please enter your reason here',
  'message.enableAccountBelowPreviousReasonOfDisabling':
    'You chose to enable this account. <0 /> Below is your previous reason of disabling this account.',
  'message.disableAccountPleaseClarifyYourReason':
    'You chose to disable this account. <0 /> Please clarify your reason',
  'message.choseMarkApplicantIdentityAsValid': `You chose to mark the applicant's identity as valid.`,
  'message.choseMarkApplicantIdentityAsInvalid': `You chose to mark the applicant's identity as invalid.`,
  'message.displayNameRequired': 'Please enter display name.',
  'message.displayNameMax100Char': 'The display name has a maximum of 100 characters.',
  'message.displayNameMin2Char': 'The display name has a minimum of 2 characters.',
  'message.displayNameMustBeUserName': `The display name must be alphabets only, no numeric, no special characters (except ,-/'@).`,
  'message.pleaseEnterApplicantDateOfBirth': 'Please enter applicant’s date of birth',
  'message.ageBetween': 'User age must be between {{from}} and {{to}}.',
  'message.pleaseEnterGenderInOptionsAbove': 'Please enter your gender in the options above.',
  'message.formatInvalidInNRIC/FIN': 'The NRIC/FIN format is invalid.',
  'message.NRIC/FINrequired': 'Please enter NRIC/FIN.',
  'message.finDocumentRequired': 'Please upload FIN document.',
  'message.pleaseEnterApplicantContactNumber': 'Please enter applicant’s contact number.',
  'message.emailMustBeValidEmailAddress': 'The email must be a valid email address.',
  'message.pleaseEnterApplicantEmail': 'Please enter applicant’s email.',
  'message.validateTypeFileApplicantFoodHygieneCert':
    'The applicant food hygiene cert filename must be a file of type',
  'message.pleaseUploadFoodHygieneCert': 'Please upload the food hygiene cert.',
  'message.minimumRatingLargerThanOrEqualTo0':
    'The minimum rating must be larger than or equal to 0.',
  'message.maximumRatingLargerThanOrEqualTo0':
    'The maximum rating must be larger than or equal to 0.',
  'message.amountJobGreaterThanOrEqualTo0': 'The amount job must be greater than or equal to 0.',
  'message.minimumRatingLessThan5': 'The minimum rating must be less than 5.',
  'message.maximumRatingLessThan5': 'The maximum rating must be less than or equal to 5.',
  'message.minimumRatingLessThanMaximumRating':
    'The minimum rating must be less than maximum rating.',
  'message.maximumRatingGreaterThanMinimumRating':
    'The maximum rating must be greater than minimum rating.',
  'message.choseDisableInstitute': 'You chose to disable this institute.',
  'message.choseEnableInstitute': 'You chose to enable this institute.',
  'message.pleaseEnterInstituteName': `Please enter institute's name`,
  'message.pleaseEnterInstituteDescription': 'Please enter main institute description',
  'message.pleaseEnterInstituteAddress': `Please enter institute's address`,
  'message.enableJobTemplate': 'You chose to enable this job template.',
  'message.disableJobTemplate': 'You chose to disable this job template.',
  'message.templateNameRequired': 'Please enter job template',
  'message.templateNameMax100Char': 'The template name has a maximum of 100 characters.',
  'message.templateNameMin2Char': 'The template name has a minimum of 2 characters.',
  'message.jobTitleMax100Char': 'The job title has a maximum of 100 characters.',
  'message.jobTitleMin2Char': 'The job title has a minimum of 2 characters.',
  'message.jobTypeRequired': 'Please select a job type',
  'message.jobDescriptionRequired': 'Please enter job description',
  'message.jobStartDateRequired': 'Please select job start date.',
  'message.jobStartDateBeforeJobEndDate': 'The job start date must be before the job end date',
  'message.jobEndDateRequired': 'Please select job end date.',
  'message.jobEndDateAfterJobStartDate': 'The job end date must be after the job start date',
  'message.hourlyRateGreaterThanZero': 'The hourly rate must be larger than 0.',
  'message.dateMustBeBeforeOrEqualCurrentDateTime':
    'The date must be before or equal to current datetime',
  'message.pleaseEnterAssigningAmount': 'Please enter valid assigning amount',
  'message.confirmAssignCredits': 'Do you want to process with your selection?',
  'message.minimumAssignAmountLessThanMaximumAssignAmount':
    'The minimum assign amount must be less than maximum assign amount.',
  'message.maximumAssignAmountGreaterThanMinimumAssignAmount':
    'The maximum assign amount must be greater than minimum assign amount.',

  'message.selectedPayment': 'You have selected <0 ><1> {{countSelected}} </1></0> payment',
  'message.selectedMultiplePayment':
    'You have selected <0 ><1> {{countSelected}} </1></0> payments',

  'message.wantToProcessThesePayments': 'Do you want to process these payments ?',

  'message.applicantMustCompleteFeedbackBeforePaymentIsProcessed':
    'Applicant must complete feedback before the payment is processed',
  'message.applicantJobDetailsOnlyShownWhenClockOutDone': `Applicant's job details are only shown when clock-out is done.`,
  'message.jobNotFound': 'Job not found',

  'message.pleaseSelectClockInTime': 'Please select clock in time.',
  'message.pleaseSelectClockOutTime': 'Please select clock out time.',
  'message.pleaseSelectBreakTime': 'Please select break time.',
  'message.pleaseEnterWagePerHour': 'Please enter valid wage per hour.',
  'message.pleaseSelectBank': 'Please select a bank',
  'message.wagePerHourMustBeLargerThan0': 'The wage per hour must be larger than 0.',

  'message.clockInTimeMustBeBeforeClockOutTime':
    'The clock in time must be before the clock out time',
  'message.clockOutTimeMustBeAfterClockInTime':
    'The clock out time must be after the clock in time',

  'message.breakTimeLessThanTotalWorkingHours':
    'The break time must be less than total working hours.',
  'message.pleaseEnterAllowance': 'Please enter valid allowance.',
  'message.jodAllowanceLargerThan0': 'The JOD allowance must be larger than 0.',

  'message.bankHolderNameLessThanOrEqualTo35Char':
    'The bank holder name must be less than or equal to 35 characters.',
  'message.bankAccountNumberMustBeBetween8to15Digits':
    'The bank account number must be between 8 to 15 digits',
  'message.bankAccountNumberMustBeAtLeast':
    'The bank account number must be at least {{count}} digits',
  'message.bankAccountNumberMustBeBetween9to12Digits':
    'The bank account number must be between 9 to 12 digits',
  'message.min12To16DigitsNoDashOtherSpecialChar':
    'Min 12 to 16 digits, no dash and other special chars.',
  'message.min9To12DigitsNoDashOtherSpecialChar':
    'Min 9 to 12 digits, no dash and other special chars.',
  'message.minCountDigitsNoDashOtherSpecialChar':
    'Min {{count}} digits, no dash and other special chars.',
  'message.min8To15DigitsNoDashOtherSpecialChar':
    'Min 8 to 15 digits, no dash and other special chars.',

  'message.bankHolderNameFormatIsInvalid': 'The bank holder name format is invalid.',
  'message.bankAccountNumberFormatIsInvalid': 'The bank account number format is invalid.',

  'message.limitTo35CharAlphaOnlyNoDashOtherSpecialChar':
    'Limit to 35 chars, alpha only, no dash and other special chars',

  'message.wantToProcessThisPayment': 'Do you want to process this payment?',

  'message.wantToDeleteJob': 'Do you want to delete <0/> this job?',
  'message.wantToRejectJob': 'Do you want to reject <0/> this job?',
  'message.wantToApproveJob': 'Do you want to approve <0/> this job?',
  'message.pleaseScanQrCode': 'Please scan QR code',
  'message.cancelApplicant': 'Do you want to cancel this applicant? (The job will not be reposted automatically.)',
  'message.clockInBeforeClockOut': 'The clock in time must be before the clock out time.',
  'message.clockOutAfterClockIn': 'The clock out time must be after the clock in time.',
  'message.ratingRequired': 'Please select a rating.',
  'message.workingHoursMoreThan18Hours': 'Clock in and out period is more than 18 hours.',
  'message.deletedAccount': 'Deleted Account',
  'message.haveSelected': 'You have selected',
  'message.wantToProcess': 'Do you want to process',

  'message.templateNameOptionRequired': 'Please select a template.',
  'message.locationRequired': 'Please select a location.',
  'message.dateRangeRequired': 'Please select a date range.',
  'message.jobStartDateAfterCurrent': `Job's start datetime must be after current datetime`,
  'message.jobEndDateAfterCurrent': `Job's end datetime must be after current datetime`,
  'message.jobStartBeforeJobEnd': `The start datetime must be before the end datetime`,
  'message.earningPerHourRequired': 'Please enter valid earning per hour.',
  'message.earningPerHourLargerThan0': 'The earning per hour must be larger than 0.',
  'message.atLeastOneWorkingDay': 'Please select at least one working day.',
  'message.postingNewJob': 'You are posting a new job.',

  'message.wantToChangeValueOfSelectedCountOtherConfiguration':
    'Do you want to change the <0 />  value of {{selectedCount}} configuration ?',
  'message.wantToChangeValueOfSelectedCountMultipleOtherConfiguration':
    'Do you want to change the <0 />  value of {{selectedCount}} configurations ?',
  'message.wantToChangeValueOfBadgeConfiguration':
    'Do you want to change the <0 />  value of {{label}} configuration ?',

  'message.wantToChangeStatusOfEmailNotification':
    ' Do you want to change the <0 /> status of {{selectedCount}} email notification ?',
  'message.wantToChangeStatusPushNotification':
    'Do you want to change the <0 /> status of {{selectedCount}} push notification ?',
  'message.wantToChangeStatusSMSNotification':
    ' Do you want to change the <0 /> status of {{selectedCount}} sms notification ?',
  'message.validateTypeFileLocationLogo': 'The location logo filename must be a file of type',

  'message.pleaseEnterPassword': 'Please enter password',
  'message.pleaseEnterNewPassword': 'Please enter new password',
  'message.pleaseEnterConfirmPassword': 'Please enter confirm password',
  'message.newPasswordMinimum6Char': 'New password must be minimum of 6 characters',
  'message.confirmPasswordMinimum6Char': 'Confirm password must be minimum of 6 characters',
  'message.passwordMinimum6Char': 'Password must be minimum of 6 characters.',

  'message.choseDisableRole': 'You chose to disable this role.',
  'message.choseEnableRole': 'You chose to enable this role.',
  'message.choseDisableUser': 'You chose to disable this user.',
  'message.choseEnableUser': 'You chose to enable this user.',
  'message.pleaseSelectRole': 'Please select a role',
  'message.pleaseEnterRoleName': 'Please enter role name',
  'message.pleaseEnterDescription': 'Please enter description',
  'message.descriptionMustBeGreaterThan255Char':
    'The description must not be greater than 255 characters.',
  'message.minimumWageLargerOrEqual0': 'The minimum wage must be larger than or equal to 0',
  'message.minimumWageLessThanMaximumWage': 'The minimum wage must be less than maximum wage',
  'message.maximumWageGreaterThanMinimumWage':
    'The maximum wage must be greater than minimum wage.',
  'message.maximumWageLargerOrEqual0': 'The maximum wage must be larger than or equal to 0',
  'message.wantToUpdateWagePerHourForAJob':
    'Do you want to update <0/> The wage per hour rate from {{currentWage}} to {{newWage}} for {{jobCount}} job',
  'message.wantToUpdateWagePerHourForMultiJob':
    'Do you want to update <0/> The wage per hour rate from {{currentWage}} to {{newWage}} for {{jobCount}} jobs',
  'message.currentWageRequired': 'Please enter valid current wage per hours.',
  'message.currentWageLargerThan0': 'The current wage per hours must be larger than 0.',
  'message.newWageRequired': 'Please enter valid new wage per hours.',
  'message.newWageLargerThan0': 'The new wage per hours must be larger than 0.',
  'message.differentValidValueForCurrentAndNewWage':
    'Please enter different valid values for current and new wages per hour.',
  'message.selectedAJob': 'You have selected <0><1> {{selectedCount}} </1></0> job',
  'message.selectedMultipleJob': 'You have selected <0><1> {{selectedCount}} </1></0> jobs',
  'message.wantToCancelAJob': 'Do you want to cancel this job',
  'message.wantToCancelMultipleJob': 'Do you want to cancel these jobs',

  'message.totalNumberOfAvailableCredits': 'Total Number of <0 /> Available Credits',
  'message.consumedCreditCurrentMonth': 'Consumed Credit <0 /> (Current Month)',
  'message.jodCreditsAvailableUnderAreaManager': 'JOD Credits Available <0 /> under Area Manager',
  'message.jodCreditsAvailableUnderLocationManager':
    'JOD Credits Available <0 /> under Location Manager',
  'message.minimumTotalJodCreditLessThanMaximumTotalJodCredit':
    'The minimum total jod credit must be less than maximum total jod credit.',
  'message.maximumTotalJodCreditGreaterThanMinimumTotalJodCredit':
    'The maximum total jod credit must be greater than minimum total jod credit.',

  'message.wantToChangeTheLogo': 'Do you want to change the logo?',

  'message.totalNumberOfPartnerCompanies': ' Total Number of <0 /> Partner Companies',
  'message.totalNumberOfHQManagers': 'Total Number of <0 /> HQ Managers',
  'message.totalNumberOfFuturePayments': 'Total Number of <0 /> Future Payments',

  'message.minimumLessThanMaximumAvailableConsumed':
    'The minimum available consumed must be less than maximum available consumed.',
  'message.maximumGreaterThanMinimumAvailableConsumed':
    'The maximum available consumed must be greater than minimum available consumed.',

  'message.totalNumberOfJobs': 'Total Number of <0 /> Jobs',
  'message.totalNumberOfRejectCandidates': ' Total Number of <0 /> Rejected Candidates',
  'message.createdApplicantUnsuccess': 'Created applicant unsuccessfully',
  'message.updatedApplicantUnsuccess': 'Updated applicant unsuccessfully',
  'message.enableApplicantUnsuccess': 'Enable applicant unsuccessfully',
  'message.disableApplicantUnsuccess': 'Disable applicant unsuccessfully',
  'message.updateApplicantIdentityStatusUnsuccess':
    'Update applicant identity status unsuccessfully',
  'message.downloadSuccess': 'Download Successfully',

  'message.createdAreaManagerUnsuccess': 'Created area manager unsuccessfully',
  'message.updatedAreaManagerUnsuccess': 'Updated area manager unsuccessfully',
  'message.disableAreaManager': 'Disable manager unsuccessfully',
  'message.enableAreaManager': 'Enable manager unsuccessfully',

  'message.createdCompanyUnsuccess': 'Created company unsuccessfully',
  'message.updatedCompanyUnsuccess': 'Updated company unsuccessfully',
  'message.disableCompanyUnsuccess': 'Disable company unsuccessfully',
  'message.enableCompanyUnsuccess': 'Enable company unsuccessfully',
  'message.bannerCompanyHasNotBeenUploaded': 'Banner company has not been uploaded yet!',
  'message.uploadBannerCompanyUnsuccess': 'Upload banner company unsuccessfully',
  'message.exportSuccess': 'Export Successfully',

  'message.changeStatusNotificationUnsuccess': 'Change status notification unsuccessfully',
  'message.changeValueOtherConfigurationUnsuccess':
    'Change value other configuration unsuccessfully',

  'message.assignCreditUnsuccess': 'Assign credit unsuccessfully',
  'message.haveNoPermissionAccessFeature': 'You have no permission to access this feature',
  'message.userAdminLogoFileHasNotBeenUploadYet': 'User admin logo file has not been uploaded yet!',
  'message.updateFailed': 'Update failed',
  'message.getAdminLogoFailed': 'Get admin logo failed',
  'message.disableEducationalInstitutesUnsuccess': 'Disable Education Institute Unsuccessfully',
  'message.enableEducationalInstitutesUnsuccess': 'Enable Education Institute Unsuccessfully',

  'message.createdHQManagerUnsuccess': 'Created HQ manager unsuccessfully',
  'message.updatedHQManagerUnsuccess': 'Updated HQ manager unsuccessfully',
  'message.disableHQUserUnsuccess': 'Disable HQ User Unsuccessfully',
  'message.enableHQUserUnsuccess': 'Disable HQ User Unsuccessfully',

  'message.createdUserUnsuccess': 'Created user unsuccessfully',
  'message.updatedUserUnsuccess': 'Updated user unsuccessfully',
  'message.disableUserUnsuccess': 'Disable user Unsuccessfully',
  'message.enableUserUnsuccess': 'Enable user Unsuccessfully',

  'message.disableJobTemplateUnsuccess': 'Disable job template unsuccessfully',
  'message.enableJobTemplateUnsuccess': 'Enable job template unsuccessfully',
  'message.createdJobTemplateUnsuccess': 'Created job template unsuccessfully',
  'message.updatedJobTemplateUnsuccess': 'Updated job template unsuccessfully',

  'message.createdJobTypeUnsuccess': 'Created job type unsuccessfully',
  'message.updatedJobTypeUnsuccess': 'Updated job type unsuccessfully',
  'message.disableJobTypeUnsuccess': 'Disable job type unsuccessfully',
  'message.enableJobTypeUnsuccess': 'Enable job type unsuccessfully',

  'message.createdNewJobUnsuccess': 'Created new job unsuccessfully',
  'message.updatedNewJobUnsuccess': 'Updated new job unsuccessfully',

  'message.deletedJobFailed': 'Delete job failed',
  'message.approveJobFailed': 'Approve job failed',
  'message.cancelApplicantFailed': 'Cancel applicant failed',
  'message.cancelUnsuccess': 'Cancel unsuccessfully',

  'message.createdLocationManagerUnsuccess': 'Created location manager unsuccessfully',
  'message.updatedLocationManagerUnsuccess': 'Updated location manager unsuccessfully',

  'message.createdLocationUnsuccess': 'Created location unsuccessfully',
  'message.updatedLocationUnsuccess': 'Updated location unsuccessfully',
  'message.disableLocationUnsuccess': 'Disable location unsuccessfully',
  'message.enableLocationUnsuccess': 'Enable location unsuccessfully',

  'message.confirmUnsuccess': 'Confirm unsuccessfully',
  'message.downloadApplicantJobSummaryUnsuccess': `Download applicant's job summary unsuccessfully`,

  'message.createdRoleUnsuccess': 'Created Role Unsuccessfully',
  'message.updatedRoleUnsuccess': 'Updated Role Unsuccessfully',
  'message.disableRoleUnsuccess': 'Disable Role Unsuccessfully',
  'message.enableRoleUnsuccess': 'Enable Role Unsuccessfully',
  'message.passwordChangeFailed': 'Password change failed',

  'message.youNoPermissionAccessPage': 'You have no permission to access this page',

  'message.hourlyRate': 'Please enter valid hourly rate',
  'message.tableSummary': 'Showing {{from}} to {{to}} of {{total}} records',
  'message.invalidPasswordResetLink':
    'It looks like you clicked on an invalid password reset link. Please try again.',
  'message.duplicatedEmailError': 'The email has already been taken.',
  'message.valueMustBeAlphabeticOnly': 'Value must be alphabetic only.',
  'message.candidate': 'candidate',
  'message.candidates': 'candidates',
  'message.valueMustBeNumericOnly': 'Value must be numeric only.',
  'message.valueMustBeAlphaNumeric': 'Value must be alphanumeric.',
  'message.pleaseSelectAverageRating': 'Please select average rating',
  'message.commonRequired': 'field required',
  'message.locationManagerIsNull': 'You are posting a new job without selecting a Location Manager. The location manager will not be able to see this job post. Please select a Location Manager to avoid this.',

  // END MESSAGE

  // START VALUE
  'value.all': 'All',
  'value.enable': 'Enable',
  'value.disable': 'Disable',

  'value.include': 'Include',
  'value.exclude': 'Exclude',

  'value.male': 'Male',
  'value.female': 'Female',
  'value.other': 'Other',
  'value.recentAddedLocations': 'Recently Added Locations',
  'value.enableLocations': 'Enable Locations',
  'value.disableLocations': 'Disable Locations',
  'value.location': 'Location',
  'value.area': 'Area',
  'value.locationManager': 'Location Managers',
  'value.areaManager': 'Area Managers',
  'value.recentAddedManagers': 'Recently Added Managers',
  'value.disableManagers': 'Disable Managers',
  'value.enableManagers': 'Enable Managers',
  'value.jobWithApplicants': 'Job with Applicants',
  'value.recentAddedJobTemplates': 'Recently Added Job Templates',
  'value.enableJobTemplates': 'Enable Job Templates',
  'value.disableJobTemplates': 'Disable Job Templates',
  'value.yearOld': '{{age}} years old',

  'value.inThisSlot': 'In this slot only.',
  'value.inAllSlot': 'In all undone slots.',
  'value.singleDayJobs': 'Single-Day Jobs',
  'value.multiDayJobs': 'Multi-Day Jobs',
  'value.pending': 'Pending',
  'value.open': 'Open',
  'value.active': 'Active',
  'value.completed': 'Completed',
  'value.cancelled': 'Cancelled',
  'value.expiredWithNoApplicants': 'Expired - No Applicants',
  'value.expiredNoSelection': 'Expired - No Selection done',
  'value.activeButNotClockIn': 'Active (Not clocked in yet)',

  'value.jan': 'Jan',
  'value.feb': 'Feb',
  'value.mar': 'Mar',
  'value.apr': 'Apr',
  'value.may': 'May',
  'value.jun': 'Jun',
  'value.jul': 'Jul',
  'value.aug': 'Aug',
  'value.sep': 'Sep',
  'value.oct': 'Oct',
  'value.nov': 'Nov',
  'value.dec': 'Dec',
  'value.unprocessed': 'Unprocessed',
  'value.processed': 'Processed',
  'value.inProgress': 'In Progress',
  'value.perPage': '/ page',
  'value.hired': 'Selected',
  'value.rejected': 'Rejected by Manager',
  'value.rejectedPartially': 'Partially Rejected by Manager',
  'value.selfCancelled': 'Cancelled by Applicant',
  'value.ack1': 'Early Acknowledgment',
  'value.ack2': 'Final Acknowledgment',
  'value.clockedIn': 'Clocked In',
  'value.clockedOut': 'Clocked Out',
  'value.adminDisable': 'Rejected by Super Admin',
  'value.adminCompanyDisable': 'Disabled by Super Admin (Company)',
  'value.adminOutletDisable': 'Disabled by Super Admin (Outlet Location)',
  'value.adminApplicantDisable': 'Disabled by Super Admin (Applicant)',
  'value.noShow': 'No Show',
  'value.noOptions': 'No options',
  'value.applied': 'Applied',
  'value.selected': 'Selected',
  'value.unselected': 'Unselected',
  'value.preSelectSelfCancelled': 'Cancelled (Before Selection)',
  'value.timeOverlapReject': 'Auto-cancelled (Time Overlap)',
  'value.postSelectSelfCanceled': 'Cancelled (After Selection)',
  'value.unknown': 'Unknown',
  'value.su': 'Su',
  'value.mo': 'Mo',
  'value.tu': 'Tu',
  'value.we': 'We',
  'value.th': 'Th',
  'value.fr': 'Fr',
  'value.sa': 'Sa',
  'value.unverifiedIdentity': 'Unverified Identity',
  'value.validIdentity': 'Valid Identity',
  'value.invalidIdentity': 'Invalid Identity',
  'value.unavailableIdentity': 'Unavailable Identity',
  'value.completedWithoutAppliedApplicant': 'Completed without applied applicant',
  'value.completedWithoutHiringManager': 'Completed without hiring by manager',
  'value.noHiring': 'Expired without Hiring',
  'value.noApplicants': 'Expired without Applicant',

  // END VALUE

  // START SUFFIX

  // END SUFFIX
};

export interface EnglishTranslation extends Record<keyof typeof en, string> { }
