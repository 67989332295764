import { EnglishTranslation } from './en';

export const vn: EnglishTranslation = {
  'button.allCandidates': 'Ứng viên',
  'button.assignCredit': 'Cấp xu Jod',
  'button.rating': 'Xếp hạng',
  'button.download': 'Tải xuống',
  'button.filter': 'Bộ lọc',
  'button.sign_in': 'Đăng nhập',
  'button.submit': 'Gửi',
  'button.save': 'Lưu',
  'button.cancel': 'Huỷ',
  'button.close': 'Đóng',
  'button.newLocation': 'Tạo chi nhánh',
  'button.reset': 'Đặt lại',
  'button.resetFilters': 'Đặt lại bộ lọc',
  'button.confirm': 'Đồng ý',
  'button.yesIDo': 'Tôi đồng ý',
  'button.noIDoNot': 'Tôi không đồng ý',
  'button.addCompany': 'Tạo đối tác',
  'button.addJobType': 'Tạo mục việc',
  'button.addManager': 'Tạo quản lý',
  'button.addApplicant': 'Tạo ứng viên',
  'button.addNewSchool': 'Tạo trường học',
  'button.addNewRole': 'Tạo vai trò',
  'button.addNewUser': 'Tạo người dùng',
  'button.addNewInternalRole': 'Tạo vai trò nội bộ',
  'button.addNewInternalUser': 'Tạo người dùng nội bộ',
  'button.addNewSuperHqUser': 'Thêm Người Dùng Super HQ Mới',
  'button.addNewHQManager': 'Tạo giám đốc khối',
  'button.credit': 'Xu Jod',
  'button.view': 'Xem',
  'button.edit': 'Sửa',
  'button.yes': 'Tôi đồng ý',
  'button.newManager': 'Tạo quản lý',
  'button.manager': 'Quản lý',
  'button.enable': 'Kích hoạt',
  'button.disable': 'Vô hiệu',
  'button.newJobTemplate': 'Tạo mẫu việc',
  'button.bulkFastExport': 'Xuất hàng loạt',
  'button.downloadPayment': 'Tải thanh toán',
  'button.downloadApplicantJobSummary': 'Tóm tắt công việc',
  'button.copy': 'Sao chép',
  'button.delete': 'Xoá',
  'button.approveJob': 'Phê duyệt',
  'button.rejectJob': 'Từ chối',
  'button.copyJob': 'Sao chép',
  'button.deleteJob': 'Xoá',
  'button.editJob': 'Sửa',
  'button.done': 'Hoàn thành',
  'button.back': 'Quay lại',
  'button.profile': 'Hồ sơ',
  'button.continue': 'Tiếp tục',
  'button.updateHourlyRate': 'Cập nhật lương',
  'button.changePassword': 'Đổi mật khẩu',
  'button.assign': 'Cấp Jod xu',
  'button.logOut': 'Đăng xuất',
  'button.ok': 'Ok',
  'button.detail': 'Chi tiết',
  'button.companyHistory': 'Lịch sử',
  'button.locationHistory': 'Lịch sử',
  'button.areaHistory': 'Lịch sử',

  'label.superAdmin': 'Admin',
  'label.hqUser': 'Giám đốc khối',
  'label.areaUser': 'Giám đốc vùng',
  'label.locationUser': 'Giám đốc chinh nhánh',
  'label.managerDetail': 'Chi tiết',
  'label.rememberMe': 'Ghi nhớ đăng nhập',
  'label.forgotPassword': 'Quên mật khẩu?',
  'label.phoneNumber': 'Số điện thoại',
  'label.contactNumber': 'Số điện thoại',
  'label.firstName': 'Tên',
  'label.lastName': 'Họ',
  'label.name': 'Tên',
  'label.displayName': 'Tên hiển thị',
  'label.email': 'Email',
  'label.workEmail': 'Email công việc',
  'label.notification': 'Thông báo',
  'label.address': 'Địa chỉ',
  'label.gender': 'Giới tính',
  'label.dateOfBirth': 'Ngày sinh',
  'label.referralCode': 'Mã giới thiệu',
  'label.foodHygiene': 'Bắng vệ sinh an toàn thực phẩm',
  'label.foodHygieneCert': 'Bằng vệ sinh an toàn thực phẩm',
  'label.sunOfJODBadge': 'Huy hiệu<0/> Mặt trời của Jod',
  'label.newJobNotifications': 'Thông báo việc mới',
  'label.unsubscribeNews': 'Huỷ đăng ký <0 /> thông báo',
  'label.hqCompany': 'Đối tác khối',
  'label.deletedAccount': 'Tài khoản xoá',
  'label.language': 'Ngôn ngữ',
  'label.download': 'Tải xuống',
  'label.workHistory': 'Lịch sử',
  'label.currentOccupation': 'Công việc hiện tại',
  'label.job': 'Công việc',
  'label.jobs': 'Công việc',
  'label.activeJobs': 'Việc chờ làm',
  'label.openJobs': 'Việc mới',
  'label.completedJobs': 'Việc hoàn thành',
  'label.currentMonth': 'Tháng này',
  'label.todayHired': 'Tuyển hôm nay',
  'label.totalHired': 'Số ứng viên tuyển',
  'label.totalCredits': 'Số xu Jod',
  'label.numberOfHiredStaff': 'Số tuyển',
  'label.hqAvailableJodCredits': 'Số xu Jod hiện có (HQ) <0/>',
  'label.locationJodCredits': 'Số xu Jod hiện có (chi nhánh)</0>',
  'label.consumedCredits': 'Số xu Jod đã dùng',
  'label.generalRating': 'Xếp hạng chung',
  'label.serviceContract': 'Hợp đồng dịch vụ',
  'label.locationName': 'Tên chi nhánh',
  'label.availableCredits': 'Số xu Jod đang có',
  'label.areaManager': 'Quản lý khu vực',
  'label.locationManagers': 'Quản lý chi nhánh',
  'label.areaManagers': 'Quản lý khu vực',
  'label.function': 'Chức năng',
  'label.min': 'Tối thiểu',
  'label.max': 'Tối đa',
  'label.recent': 'Tạo gần đây',
  'label.recentAddCompanies': 'đối tác tạo gần đây',
  'label.enableCompanies': 'Kích hoạt',
  'label.disableCompanies': 'Vô hiệu đối tác',
  'label.partnerCompanies': 'Đối tác',
  'label.activeCompanies': 'Đối tác đang hoạt động',
  'label.companyName': 'Tên đối tác',
  'label.companyAddress': 'Địa chỉ đối tác',
  'label.companyStatus': 'Tình trạng',
  'label.businessRegistration': 'Đăng ký kinh doanh',
  'label.minJODCreditLimit': 'Giới hạn xu Jod (tối thiểu)',
  'label.jobTypeStatus': 'Tình trạng',
  'label.numberOfAvailablePositions': 'Số công việc',
  'label.numberOfAvailableCompanies': 'Số đối tác',
  'label.minimumAvailablePositions': 'Số việc tối thiểu',
  'label.maximumAvailablePositions': 'Số việc tối đa',
  'label.minimumAvailableCompanies': 'Số đối tác tối thiểu',
  'label.maximumAvailableCompanies': 'Số đối tác tối đa',
  'label.minAvailablePositions': 'Số việc tối thiểu',
  'label.maxAvailablePositions': 'Số việc tối đa',
  'label.minAvailableCompanies': 'Số đối tác tối thiểu',
  'label.maxAvailableCompanies': 'Số đối tác tối đa',
  'label.generalDescription': 'Mô tả công việc',
  'label.jobDescription': 'Mô tả công việc',
  'label.comment': 'Bình luận',
  'label.jobTitle': 'Chức danh',
  'label.hqManager': 'Giám đốc khối',
  'label.hqMangerName': 'Tên giám đốc khối',
  'label.contactInformation': 'Thông tin liên lạc',
  'label.company': 'Đối tác',
  'label.recentAddHQUsers': 'Giám đốc khối tạo gần đây',
  'label.companyHQMainContactUserDetails': 'Thông tin liên hệ chính',
  'label.disableHQUsers': 'Vô hiệu',
  'label.enableHQUsers': 'Kích hoạt',
  'label.hqUserStatus': 'Tình trạng',
  'label.totalNumberOfHQUsers': 'Số giám đốc khối',
  'label.numberOfActiveHQUsers': 'Số giám đốc khối hoạt động <0 />',
  'label.numberOfDisableHQUsers': 'Số giám đốc khối vô hiệu <0 />',
  'label.recentAddJobTypes': 'Danh mục việc tạo gần đây',
  'label.disableJobTypes': 'Vô hiệu',
  'label.enableJobTypes': 'Kích hoạt',
  'label.enabledCompanies': 'Đối tác đã kích hoạt',
  'label.disabledCompanies': 'Đối tác đã vô hiệu',
  'label.recentAdded': 'Tạo gần đây',
  'label.totalNumberOfLocations': 'Số chi nhánh',
  'label.locationStatus': 'Tình trạng',
  'label.minimumAvailableCredits': 'Số xu Jod tối thiểu',
  'label.maximumAvailableCredits': 'Số xu Jod tối đa',
  'label.minimumConsumedCredits': 'Số xu Jod đã dùng (tối thiểu)',
  'label.maximumConsumedCredits': 'Số xu Jod đã dùng (tối đa)',
  'label.minAvailableCredits': 'Số xu Jod tối thiểu',
  'label.maxAvailableCredits': 'Số xu Jod tối đa',
  'label.minConsumedCredits': 'Số xu Jod đã dùng (tối thiểu)',
  'label.maxConsumedCredits': 'Số xu Jod đã dùng (tối đa)',
  'label.minAssignedCredits': 'Số xu Jod đã cấp (tối thiểu)',
  'label.maxAssignedCredits': 'Số xu Jod đã cấp (tối đa)',
  'label.minAvailableConsumed': 'Số xu Jod tối thiểu',
  'label.maxAvailableConsumed': 'Số xu Jod tối đa',
  'label.minimumAvailableConsumed': 'Số xu Jod đã dùng (tối thiểu)',
  'label.maximumAvailableConsumed': 'Số xu Jod đã dùng (tối đa)',
  'label.locationAddress': 'Địa chỉ chi nhánh',
  'label.jobApprovalRequired': 'Yêu cầu duyệt việc <0/>',
  'label.locationManagerCanCreateJob': 'Quản lý chi nhánh <0/> có thể tạo việc',
  'label.createFromTemplate': 'Chỉ tạo từ mẫu <0/>',
  'label.creditDeductionAtLocationLevel': 'Trừ xu Jod từ chi nhánh </0>',
  'label.autoJobPosting': 'Tự động đăng việc <0/>',
  'label.date': 'Ngày',
  'label.inProgress': 'Đang làm',
  'label.assignedCredits': 'Cấp xu Jod',
  'label.applicantName': 'Tên ứng viên',
  'label.applicantStatus': 'Tình trạng',
  'label.applicantIsFeedback': 'Đánh giá từ ứng viên',
  'label.applicantIsRating': 'Xếp hạng từ ứng viên',
  'label.identityStatus': 'Tình trạng',
  'label.managerFeedback': 'Đánh giá từ quản lý',
  'label.managerRating': 'Xếp hạng từ quản lý',
  'label.minimumRating': 'Xếp hạng tối thiểu',
  'label.maximumRating': 'Xếp hạng tối đa',
  'label.applicantsWithAmountJobs': 'Người nộp đơn có số tiền công việc',
  'label.availableBreakLineCredits': 'Xu Jod <0/> đang có',
  'label.consumedBreakLineCredits': 'Xu Jod <0/> đã dùng',
  'label.disableLocations': 'Vô hiệu',
  'label.enableLocations': 'Kích hoạt',
  'label.maskAsValid': 'Hợp lệ',
  'label.maskAsInvalid': 'Không hợp lệ',
  'label.userAccountID': 'ID tài khoản',
  'label.verifyCode': 'Mã xác minh',
  'label.school': 'Trường',
  'label.averageRating': 'Xếp hạng trung bình',
  'label.totalWorkingHours_breakLine': 'Số giờ làm',
  'label.totalCompletedJobs_breakLine': 'Số việc hoàn thành </0>',
  'label.totalCompletedJobs': 'Số việc hoàn thành',
  'label.totalNumberOfApplicants': 'Số ứng viên </0>',
  'label.numberOfActiveApplicants': 'Số ứng viên hoạt động </0>',
  'label.numberOfDisableApplicants': 'Số ứng viên vô hiệu </0>',
  'label.recentAddedApplicants': 'Ứng viên tạo gần đây',
  'label.disableApplicants': 'Vô hiệu',
  'label.enableApplicants': 'Kích hoạt',
  'label.minRating': 'Xếp hạng tối thiểu',
  'label.maxRating': 'Xếp hạng tối đa',
  'label.applicantWithNumberOfJobs': '',
  'label.manager': 'Quản lý',
  'label.managerType': 'Loại quản lý',
  'label.locations': 'Chi nhánh',
  'label.totalNoAreaManagers': 'Số giám đốc vùng</0>',
  'label.totalNoLocationManagers': 'Số quản lý chi nhánh<0/>',
  'label.managerStatus': 'Tình trạng',
  'label.location': 'Chi nhánh',
  'label.institutionName': 'Tên trường',
  'label.instituteName': 'Tên trường',
  'label.description': 'Mô tả',
  'label.educationInstituteStatus': 'Tình trạng',
  'label.totalNumberOfEducationalInstitutes': 'Số trường học </0>',
  'label.recentAddedEducationInstitutes': 'Trường tạo gần đây',
  'label.disableEducationalInstitutes': 'Vô hiệu',
  'label.enableEducationalInstitutes': 'Kích hoạt',
  'label.templateName': 'Tên mẫu',
  'label.jobStart': 'Bắt đầu việc',
  'label.jobEnd': 'Kết thúc việc',
  'label.slotStart': 'Bắt đầu ca',
  'label.slotEnd': 'Kết thúc ca',
  'label.wage': 'Lương',
  'label.applicationNo': 'Số ứng viên',
  'label.hiredNo': 'Số tuyển',
  'label.totalNumberOfJobs': 'Số việc </0>',
  'label.jobWithApplicants': 'Việc có ứng viên',
  'label.jobStart&JobEnd': 'Bắt đầu và kết thúc việc',
  'label.minimumWage': 'Lương tối thiểu',
  'label.maximumWage': 'Lương tối đa',
  'label.from': 'Từ',
  'label.to': 'Đến',
  'label.include': 'Bao gồm',
  'label.exclude': 'Loại trừ',
  'label.totalNumberOfJobTemplates': 'Số việc mẫu </0>',
  'label.jobTemplateStatus': 'Tình trạng',
  'label.jobType': 'Danh mục việc',
  'label.hourlyRate': 'Lương theo giờ',
  'label.specialInstructions': 'Yêu cầu đặc biệt',
  'label.specialInstructions_breakLine': 'Yêu cầu đặc biệt <0/>',
  'label.createdDate': 'Ngày tạo',
  'label.timeIn': 'Giờ vào',
  'label.timeOut': 'Giờ ra',
  'label.totalHours': 'Số giờ',
  'label.totalWages': 'Số lương',
  'label.status': 'Tình trạng',
  'label.jobStatus': 'Tình trạng',
  'label.jobIsStatus': 'Tình trạng',
  'label.createMonth': 'Tháng tạo',
  'label.assigningAmount': 'Số lượng cấp',
  'label.amount': 'Số lượng',
  'label.feedback': 'Đánh giá',
  'label.assigningDate': 'Ngày cấp',
  'label.startDate': 'Ngày bắt đầu',
  'label.endDate': 'Ngày kết thúc',
  'label.minimumAssignAmount': 'Số lượng cấp tối thiểu',
  'label.maximumAssignAmount': 'Số lượng cấp tối đa',
  'label.minimumAssignedAmount': 'Số lượng cấp tối thiểu',
  'label.maximumAssignedAmount': 'Số lượng cấp tối đa',
  'label.selectAll': 'Chọn tất cả',
  'label.jodID': 'ID việc',
  'label.slotID': 'ID ca',
  'label.clockIn': 'Chấm công vào',
  'label.clockOut': 'Chấm công ra',
  'label.netPay': 'Lương ròng',
  'label.detail': 'Chi tiết',
  'label.rating': 'Xếp hạng',
  'label.breakTime': 'Giờ nghỉ',
  'label.deductedCredits': 'Xu Jod cần dùng',
  'label.jodCredit': 'Xu Jod',
  'label.jobHistory': 'Lịch sử',
  'label.locationCredit': 'Số xu Jod tại chi nhánh',
  'label.processedDate': 'Ngày xử lý',
  'label.jodAllowance': 'Phụ cấp',
  'label.jodAllowanceDeduction': 'Phụ cấp giảm trừ',
  'label.jodAllowanceDeductionLabel': 'Phụ cấp JOD',
  'label.companyAllowanceDeductionLabel': 'Phụ cấp công ty',
  'label.bankPaid': 'Đã trả',
  'label.bankName': 'Tên ngân hàng',
  'label.bankHolderName': 'Tên chủ tài khoản',
  'label.bankAccountNumber': 'Số tài khoản',
  'label.paymentStatus': 'Tình trạng',
  'label.recentAddedPayments': 'Thanh toán tạo gần đây',
  'label.jobDetail': 'Chi tiết việc',
  'label.jobDetails': 'Chi tiết việc',
  'label.wagePerHour': 'Lương theo giờ',
  'label.jodAmendedDetails': 'Jod điều chỉnh',
  'label.noAdjustmentMade': 'Không có điều chỉnh',
  'label.completedByAdmin': 'Admin hoàn thành',
  'label.unavailable': 'Không có',
  'label.fullName': 'Họ và tên',
  'label.totalJodCredits': 'Số xu Jod',
  'label.insuranceAmount': 'Tiền bảo hiểm',
  'label.netPayableAmount': 'Lương ròng',
  'label.accountHolder': 'Chủ tài khoản',
  'label.adjustedJODCredit': 'Điều chỉnh xu Jod',
  'label.adjustedJodCredits': 'Điều chỉnh xu Jod',
  'label.adjustedAt': 'Điều chỉnh tại',
  'label.totalNumberOfPayments': 'Số thanh toán </0>',
  'label.totalNumberOfProcessedPayments': 'Số thanh toán đã xử lý </0>',
  'label.totalNumberOfUnpaidPayments': 'Số thanh toán chưa xử lý </0>',
  'label.applicantID': 'ID ứng viên',
  'label.applicantFirstName': 'Tên',
  'label.applicantLastName': 'Họ',
  'label.applicantNRIC/FICNumber': 'Số CCCD/CMND',
  'label.applicantEmail': 'Email',
  'label.applicantContactNumber': 'Số điện thoại',
  'label.applicantGender': 'Giới tính',
  'label.applicantDoB': 'Ngày sinh',
  'label.yearOld': '{{age}} tuổi',
  'label.applicantAge': 'Tuổi',
  'label.applicantAddress': 'Địa chỉ',
  'label.applicantRating': 'Xếp hạng ứng viên',
  'label.applicantFeedback': 'Đánh giá ứng viên',
  'label.applicantEducationalInstitution': 'Trường',
  'label.applicantBankName': 'Tên ngân hàng',
  'label.applicantV1BankName': '',
  'label.applicantBankAccountName': 'Tên chủ tài khoản',
  'label.applicantBankAccountNumber': 'Số tài khoản',
  'label.jobStartDateTime': 'Bắt đầu việc',
  'label.jobEndDateTime': 'Kết thúc việc',
  'label.totalExpectedHours': 'Số giờ dự kiến',
  'label.totalExpectedWages': 'Số lương dự kiến',
  'label.jobCreatedDateTime': 'Thời gian tạo việc',
  'label.daysOfJobCreatedAndJobStart': 'Ngày tạo việc và ngày bắt đầu',
  'label.hoursOfJobCreatedAndJobStart': 'Giờ tạo việc và giờ bắt đầu',
  'label.applicantAppliedDateTime': 'Ngày ứng tuyển',
  'label.daysOfJobCreatedAndApplicantApplied': 'Ngày tạo việc và ngày ứng tuyển',
  'label.hoursOfJobCreatedAndApplicantApplied': 'Giờ tạo việc và giờ ứng tuyển',
  'label.applicantHiredDateTime': 'Ngày tuyển',
  'label.daysOfApplicantAppliedAndApplicantHired': 'Ngày ứng tuyển và ngày tuyển',
  'label.hoursOfApplicantAppliedAndApplicantHired': 'Giờ ứng tuyển và giờ tuyển',
  'label.jobApplicantStatus': 'Tình trạng',
  'label.slotApplicantStatus': 'Tình trạng',
  'label.slotStartDateTime': 'Bắt đầu ca',
  'label.slotEndDateTime': 'Kết thúc ca',
  'label.applicantClockIn': 'Ứng viên chấm công vào',
  'label.applicantClockOut': 'Ứng viên chấm công ra',
  'label.applicantCancelledJob': 'Ứng viên huỷ việc',
  'label.applicantCancellationFeedback': 'Ứng viên huỷ đánh giá',
  'label.ack1Done': 'Hoàn thành xác nhận 1',
  'label.ack2Done': 'Hoàn thành xác nhận 2',
  'label.jobCompleted': 'Hoàn thành việc',
  'label.hiringManagerID': 'ID quản lý',
  'label.hiringManagerFirstName': 'Tên',
  'label.hiringManagerLastName': 'Họ',
  'label.hiringMangerContactNumber': 'Số điện thoại',
  'label.hiringManagerEmail': 'Email',
  'label.hiringManagerRating': 'Xếp hạng quản lý',
  'label.hiringManagerFeedback': 'Đánh giá từ quản lý',
  'label.jobApplicantIsStatus': 'Tình trạng',
  'label.slotApplicantIsStatus': 'Tình trạng',
  'label.totalWorkingHours': 'Số giờ làm',
  'label.jobId': 'Mã việc',
  'label.totalDays': 'Số ngày',
  'label.bankTransfer': 'Chuyển khoản',
  'label.originalJobStart': 'Dự kiến bắt đầu việc',
  'label.originalJobEnd': 'Dự kiến kết thúc việc',
  'label.actualClockIn': 'Thực tế chấm công vào',
  'label.actualClockOut': 'Thực tế chấm công ra',
  'label.dob': 'Ngày sinh',
  'label.jodCompletedJobs': 'Việc hoàn thành với Jod',
  'label.view': 'Xem',
  'label.select': 'Chọn',
  'label.locationManager': 'Quản lý chi nhánh',
  'label.earningPerHours': 'Lương theo giờ',
  'label.workingDay': 'Chọn ngày làm',
  'label.dateAndTime': 'Ngày giờ',
  'label.content': 'Nội dung',
  'label.value': 'Giá trị',
  'label.push': 'Thông báo ứng dụng',
  'label.sms': 'Thông báo SMS',
  'label.id': 'ID',
  'label.enabled': 'Đã kích hoạt',
  'label.internalPermissions': 'Quyền nội bộ',
  'label.partnerPermissions': 'Quyền đối tác',
  'label.permission': 'Quyền',
  'label.roleName': 'Loại vai trò',
  'label.userName': 'Loại người dùng',
  'label.role': 'Người dùng',
  'label.userDetails': 'Chi tiết người dùng',
  'label.internalRoles': 'Vai trò nội bộ',
  'label.partnerRoles': 'Vai trò đối tác',
  'label.changeWagePerHour': 'Thay đổi lương theo giờ',
  'label.password': 'Mật khẩu',
  'label.oldPassword': 'Mật khẩu cũ',
  'label.newPassword': 'Mật khẩu mới',
  'label.confirmPassword': 'Nhập lại mật khẩu',
  'label.credit': 'Xu Jod',
  'label.jodCredits': 'Xu Jod',
  'label.employeeName': 'Tên ứng viên',
  'label.consumedCreditsPreviousMonth': 'Xu Jod đã dùng (tháng trước)',
  'label.consumedCreditsCurrentMonth': 'Xu Jod đã dùng (tháng này)',
  'label.jodCreditAvailableWithHeadquarter': 'Số Xu Jod đang có (HQ)',
  'label.test': 'Kiểm tra',
  'label.clockIn&ClockOut': 'Chấm công vào - ra',
  'label.minimumTotalJodCredit': 'Số xu Jod (tối thiểu)',
  'label.maximumTotalJodCredit': 'Số xu Jod (tối đa)',
  'label.jodCreditDeductionLevel': 'Trừ xu Jod tại',
  'label.mealBreakTime': 'Giờ nghỉ',
  'label.availableCreditBeforeDeduction': 'Số xu Jod trước khi trừ',
  'label.availableCreditAfterDeduction': 'Số xu Jod sau khi trừ',
  'label.assignBadge': 'Gắn huy hiệu',
  'label.badge': 'Huy hiệu',
  'label.allCompanies': 'Tất cả đối tác',
  'label.futurePayment': 'Thanh toán mới',
  'label.allFuturePayments': 'Tất cả thanh toán mới',
  'label.paymentDetails': 'Chi tiết thanh toán',
  'label.paymentSummary': 'Tóm tắt thanh toán',
  'label.unprocessedCredits': 'Xu Jod chưa xử lý',
  'label.processedCredits': 'Xu Jod đã xử lý',
  'label.recentAddLocations': 'Chi nhánh tạo gần đây',
  'label.locationStatus.': 'Tình trạng',
  'label.noHistory': 'Không có lịch sử',
  'label.noExperience': 'Không có kinh nghiệm',
  'label.candidateName': 'Tên ứng viên',
  'label.applyDate': 'Ngày ứng tuyển',
  'label.jobStartAndJobEnd': 'Bắt đầu việc - Kết thúc việc',
  'label.paymentProcessed': 'Thanh toán đã xử lý',
  'label.notProcessed': 'Không được xử lý',
  'label.clickCancelSorting': 'Nhấn để sắp xếp',
  'label.clickToSortAsc': 'Nhấn để xếp tăng dần',
  'label.clickToSortDesc': 'Nhấn để xếp giảm dần',
  'label.unknown': 'Không xác định',
  'label.receivedCredits': 'Xu Jod đã nhận',
  'label.feedbackFromApplicant': 'Đánh giá từ ứng viên',
  'label.feedbackFromManager': 'Đánh giá từ quản lý',
  'label.ratingFromApplicant': 'Xếp hạng từ ứng viên',
  'label.ratingFromManager': 'Xếp hạng từ quản lý',
  'label.applicantInformation': 'Thông tin ứng viên',
  'label.aptusTermsOfUseAndConditionsForCustomers':
    'Điều khoản & Điều kiện sử dụng của Jod dành cho khách hàng',
  'label.mon': 'Thứ 2',
  'label.tue': 'Thứ 3',
  'label.wed': 'Thứ 4',
  'label.thur': 'Thứ 5',
  'label.fri': 'Thứ 6',
  'label.sat': 'Thứ 7',
  'label.sun': 'Chủ Nhật',
  'label.all': 'Tất cả',
  'label.asc': 'Tăng dần',
  'label.desc': 'Giảm dần',
  'label.nric': 'CMND/CCCD',
  'label.lessThan1Years': 'Ít hơn 1 năm',
  'label.between1To2Years': 'Từ 1 đến 2 năm',
  'label.moreThan2Years': 'Hơn 2 năm',
  'label.hour': 'giờ',
  'label.hours': 'giờ',
  'label.minute': 'phút',
  'label.minutes': 'phút',
  'label.checkboxSendMailToApplicant': 'Gửi email tới người dùng',
  'label.certificates': 'Chứng chỉ',
  'label.noCertificates': 'Không có chứng chỉ',
  'label.age': 'Tuổi',
  'label.locationAverageRating': 'Xếp hạng trung bình của chi nhánh',

  'label.transactionDate': 'Transaction Date',
  'label.debit': 'Debit',
  'label.receiveSMSNotifications': 'Receive SMS Notifications',

  'placeholder.email': 'Email',
  'placeholder.password': 'Mật khẩu',
  'placeholder.uploadServiceContract': 'Đăng tải hợp đồng dịch vụ *',
  'placeholder.uploadedServiceContract': 'Đã tải hợp đồng dịch vụ',
  'placeholder.uploadBrandBanner': 'Đăng tải banner (tuỳ chọn)',
  'placeholder.uploadedBanner': 'Đã tải banner',
  'placeholder.minExpectedCredit': 'Xu Jod dự kiến (tối thiểu)',
  'placeholder.enterKeyword': 'Nhập từ khoá',
  'placeholder.enterJobTitle': 'Nhập từ khoá',
  'placeholder.credit': '0',
  'placeholder.upload': 'Tải lên',
  'placeholder.uploaded': 'Đã tải',
  'placeholder.uploadLogo': 'Tải logo',
  'placeholder.uploadBanner': 'Tải banner',
  'placeholder.uploadProfilePicture': 'Tải ảnh đại diện',
  'placeholder.firstName': 'Nhập tên',
  'placeholder.lastName': 'Nhập họ',
  'placeholder.jobTitle': 'Nhập chức danh',
  'placeholder.contactNumber': 'Nhập số điện thoại',
  'placeholder.workEmail': 'Nhập email',
  'placeholder.creditAmount': 'Số xu Jod',
  'placeholder.comment': 'Bình luận',
  'placeholder.locationName': 'Nhập tên chi nhánh',
  'placeholder.locationAddress': 'Nhập địa chỉ chi nhánh',
  'placeholder.areaManager': 'Chọn giám đốc vùng',
  'placeholder.managerType': 'Chọn chức vụ quản lý',
  'placeholder.location': 'Chọn chi nhánh',
  'placeholder.locations': 'Chọn chi nhánh',
  'placeholder.applicantFirstName': 'Tên',
  'placeholder.applicantLastName': 'Họ',
  'placeholder.applicantDisplayName': 'Tên hiển thị',
  'placeholder.applicantDateOfBirth': 'Ngày sinh',
  'placeholder.applicantNRIC/FIN': 'Số CCCD/CMND',
  'placeholder.applicantContactNumber': 'Số điện thoại',
  'placeholder.applicantEmail': 'Email',
  'placeholder.applIcantSelectSchool': 'Chọn trường',
  'placeholder.applicantReferralCode': 'Mã giới thiệu',
  'placeholder.applicantSelectHQCompany': 'Đối tác đặc biệt chọn',
  'placeholder.enterRating': 'Nhập xếp hạng',
  'placeholder.selectStatus': 'Chọn tình trạng',
  'placeholder.selectDate': 'Chọn ngày',
  'placeholder.selectLocations': 'Chọn chi nhánh',
  'placeholder.selectMonth': 'Chọn tháng',
  'placeholder.selectCompany': 'Chọn đối tác',
  'placeholder.status': 'Chọn tình trạng',
  'placeholder.startDate': 'Ngày bắt đầu',
  'placeholder.endDate': 'Ngày kết thúc',
  'placeholder.enterTemplateName': 'Nhập mẫu việc',
  'placeholder.jobType': 'Chọn danh mục việc',
  'placeholder.noDateSelected': 'Chưa chọn ngày',
  'placeholder.pleaseSelectDate': 'Chọn ngày',
  'placeholder.jobDescription': 'Nhập mô tả công việc',
  'placeholder.specialInstructions': 'Nhập yêu cấu đặc biệt',
  'placeholder.hourlyRate': 'Nhập lương theo giờ',
  'placeholder.pleaseEnterBankHolderName': 'Nhập tên chủ tài khoản thanh toán',
  'placeholder.pleaseEnterBankAccountNumber': 'Nhập số tài khoản thanh toán',
  'placeholder.selectLocation': 'Chọn chi nhánh',
  'placeholder.feedback': 'Nhập bình luận',
  'placeholder.noAppliedApplicant': 'Không có ứng viên',
  'placeholder.selectTemplateName': 'Chọn việc mẫu',
  'placeholder.selectLocationManager': 'Chọn quản lý',
  'placeholder.pleaseSelectLanguage': 'Chọn ngôn ngữ',
  'placeholder.currentWagePerHour': 'Lương theo giờ hiện tại',
  'placeholder.newWagePerHour': 'Lương theo giờ mới',
  'placeholder.allLocations': 'Tất cả chi nhánh',
  'placeholder.allJobs': 'Tất cả việc',
  'placeholder.allJobStatus': 'Tất cả tình trạng công việc',
  'placeholder.pleaseSelectAnOption': 'Chọn tuỳ chọn',
  'placeholder.pleaseSelectAColumn': 'Chọn cột',
  'placeholder.pleaseEnterAssigningAmount': 'Nhập số lượng cấp',
  'title.dashboard': 'Trang tổng hợp',
  'title.editProfile': 'Sửa hồ sơ',
  'title.companies': 'Đối tác',
  'title.company': 'Company',
  'title.newCompany': 'Tạo đối tác',
  'title.editCompany': 'Sửa đối tác',
  'title.listOfCompanies': 'Danh sách đối tác',
  'title.listOfJobTypes': 'Danh mục việc',
  'title.jobTypes': 'Danh mục việc',
  'title.jobType': 'Danh mục việc',
  'title.newJobType': 'Tạo danh mục việc',
  'title.editJobType': 'Sửa danh mục việc',
  'title.hqUsers': 'Giám đốc khối',
  'title.listOfHQUsers': 'Danh sách giám đốc khối',
  'title.newHQUsers': 'Đăng ký giám đốc khối',
  'title.editHQUsers': 'Sửa giảm đốc khối',
  'title.applicants': 'Ứng viên',
  'title.listOfApplicants': 'Danh sách ứng viên',
  'title.newApplicant': 'Tạo ứng viên',
  'title.editApplicant': 'Sửa ứng viên',
  'title.educationalInstitutes': 'Trường',
  'title.educationalInstitutesHasTrans': 'Trường',
  'title.listOfEducationInstitutes': 'Danh sách trường',
  'title.newEducationalInstitute': 'Tạo trường',
  'title.editEducationalInstitute': 'Sửa trường',
  'title.locations': 'Chi nhánh',
  'title.listOfLocations': 'Danh sách chi nhánh',
  'title.locationFeedback': 'Đánh giá chi nhánh',
  'title.billingRecords': 'Lịch sử thanh toán',
  'title.jodCredits': 'Xu Jod',
  'title.assignCredit': 'Cấp Xu Jod',
  'title.creditHistory': 'Lịch sử cấp xu Jod',
  'title.debitCreditHistory': 'Debit Credit History',
  'title.payments': 'Thanh toán',
  'title.jobSummary': 'Tổng hợp thanh toán',
  'title.jobPayments': 'Thanh toán cũ',
  'title.futurePayments': 'Thanh toán mới',
  'title.report': 'Báo cáo',
  'title.usersAndPermissions': 'Người dùng và quyền',
  'title.users': 'Người dùng',
  'title.superHqUsers': 'Người Dùng Cấp Cao Siêu Tổng',
  'title.listOfInternalUsers': 'Danh sách người dùng nội bộ',
  'title.listOfSuperHqUsers': 'Danh sách Người Dùng Siêu Quản trị',
  'title.newInternalUser': 'Tạo người dùng nội bộ',
  'title.newSuperHqUser': 'Người Dùng Siêu Quản Trị Mới',
  'title.editInternalUser': 'Sửa người dùng nội bộ',
  'title.editSuperHqUser': 'Chỉnh sửa Người Dùng Siêu Trung Tâm',
  'title.roles': 'Vai trò',
  'title.listOfRoles': 'Danh sách vai trò',
  'title.newRole': 'Tạo vai trò',
  'title.newInternalRole': 'Tạo vai trò nội bộ',
  'title.editRole': 'Sửa vai trò',
  'title.permissions': 'Quyền',
  'title.configuration': 'Cấu hình',
  'title.notifications': 'Thông báo',
  'title.notificationConfigurations': 'Cấu hình thông báo',
  'title.other': 'Khác',
  'title.otherConfigurations': 'Cấu hình khác',
  'title.badgeConfigurations': 'Cấu hình huy hiệu',
  'title.jodBadges': 'Huy hiệu Jod',
  'title.jodBadgesConfiguration': 'Cấu hình huy hiệu Jod',
  'title.locationList': 'Danh sách chi nhánh',
  'title.addNewLocation': 'Tạo chi nhánh',
  'title.managers': 'Quản lý',
  'title.managerList': 'Danh sách quản lý',
  'title.addNewManager': 'Tạo quản lý',
  'title.locationProfile': 'Hồ sơ chi nhánh',
  'title.jobTemplates': 'Việc mẫu',
  'title.jobTemplateList': 'Danh sách việc mẫu',
  'title.addNewJobTemplate': 'Tạo việc mẫu',
  'title.jobPosting': 'Đăng tuyển',
  'title.allJobPosting': 'Việc đăng tuyển',
  'title.createNewJob': 'Tạo việc',
  'title.manageJobs': 'Quản lý việc',
  'title.updateJobs': 'Cập nhật việc',
  'title.cancelJobs': 'Huỷ việc',
  'title.jobOverview': 'Tổng quan việc',
  'title.hiringOverview': 'Tổng quan tuyển dụng',
  'title.creditOverview': 'Tổng quan xu Jod',
  'title.ratingOverview': 'Xếp hạng chung',
  'title.addNewAreaManager': 'Tạo quản lý vùng',
  'title.newLocation': 'Tạo chi nhánh',
  'title.editLocation': 'Sửa chi nhánh',
  'title.assignJodCredit': 'Cấp xu Jod',
  'title.listOfManagers': 'Danh sách quản lý',
  'title.managerRegistration': 'Đăng ký quản lý',
  'title.editLocationManager': 'Sửa thông tin',
  'title.editAreaManager': 'Sửa quản lý vùng',
  'title.editManager': 'Sửa quản lý',
  'title.addJobTemplate': 'Tạo việc mẫu',
  'title.editJobTemplate': 'Sửa việc mẫu',
  'title.jobHistory': 'Lịch sử việc',
  'title.candidateProfile': 'Hồ sơ ứng viên',
  'title.listOfAllCandidates': 'Danh sách ứng viên',
  'title.candidateOfJob': 'Ứng viên vị trí',
  'title.candidatesOfNamedJob': 'Ứng viên vị trí {{job}}',
  'title.candidatesOfJob': 'Ứng viên vị trí',
  'title.qrCode': 'Mã QR',
  'title.jobInformation': 'Thông tin',
  'title.jobNote': 'Lưu ý',
  'title.workerInformation': 'Thông tin ứng viên',
  'title.jobEdit': 'Sửa',
  'title.copyJob': 'Copy',
  'title.howToUse': 'Cách sử dụng',
  'title.howToPostJobs': 'Cách đăng tuyển',
  'title.howToMakeSelections': 'Cách chọn ứng viên',
  'title.howToClockInAndOut': 'Cách chấm công',
  'title.currentAvailableCredit': 'Số xu Jod hiện có',
  'title.redirecting': 'Đang chuyển hướng',
  'title.loadPage': 'đang tải...',
  'title.unassignedCredit': 'Số xu Jod hiện có',
  'title.hqLevel': 'Giám đốc khối',
  'title.uploadCertification': 'Tải lên bằng cấp',
  'title.hiringManager': 'Quản lý tuyển dụng',
  'title.appConfig': 'Cấu hình cho app',
  'title.superHqSelectCompany': 'Chọn Công ty (HQ)',
  'title.404PageNotFound': '404 Page Not Found',
  
  'title.schedule': 'Schedule',
  'title.schedule.job': 'Job',
  'title.schedule.job.template': 'Templates',
  'title.schedule.job.paymentrules': 'Payment Rules',
  'title.workforce': 'Workforce',
  'title.workforce.manager': 'Manager',
  'title.workforce.worker': 'Worker',
  'title.outlet': 'Outlet',
  'title.payroll': 'Payroll',
  'title.admin': 'Admin',
  'title.admin.account.settings': 'Account Settings',

  'message.changePassword': 'Thay đổi mật khẩu',
  'message.confirmPasswordMustMatchPassword': 'Mật khẩu phải phù hợp với',
  'message.totalNumberOf': 'Số',
  'message.numberOf': 'Số',
  'message.actionFailed': 'Thực hiện chưa thành công, vui lòng thử lại!',
  'message.somethingWentWrong': 'Thực hiện chưa thành công, vui lòng thử lại!',
  'message.somethingWentWrong1': 'Đã xảy ra sự cố!',
  'message.emailOrPasswordIncorrect': 'Email hoặc mật khẩu chưa đúng',
  'message.enableCompany': 'Bạn đã chọn kích hoạt công ty này.',
  'message.disableCompany': 'Bạn đã chọn vô hiệu công ty này.',
  'message.disableAccount': 'Bạn đã chọn vô hiệu tài khoản này.',
  'message.enableAccount': 'Bạn đã chọn kích hoạt tài khoản này.',
  'message.validateMinLessThanMaxCredits': 'Số xu Jod tổi thiểu phải nhỏ hơn số xu Jod tối đa',
  'message.validateMaxGreaterThanMinCredits': 'Số xu Jod tối đa phải lớn hơn số xu Jod tối thiểu',
  'message.validateOnlyIntegerNumber': 'Chỉ cho phép số nguyên',
  'message.pleaseEnterContactNumber': 'Nhập số điện thoại',
  'message.contactNumberMustBeNumber': 'Phải là chữ số',
  'message.contactNumberMustBe8Digits': 'Số điện thoại bao gồm 8 chữ số',
  'message.contactNumberMustBe9Digits': 'Số điện thoại bao gồm 9 chữ số',
  'message.contactNumberMustBe10Digits': 'Số điện thoại bao gồm 10 chữ số',
  'message.contactNumber9Or10': 'Số điện thoại bao gồm 9 hoặc 10 chữ số.',
  'message.minLessThanMaxConsumeCredit':
    'Số xu Jod (đã dùng) tổi thiểu phải nhỏ hơn số xu Jod (đã dùng) tối đa',
  'message.maxGreaterThanMaxConsumeCredit':
    'Số xu Jod (đã dùng) tối đa phải lớn hơn số xu Jod (đã dùng) tối thiểu',
  'message.validateNotBeGreaterThan255Characters': 'Tiêu đề tối đa 255 ký tự',
  'message.validateTypeFileCompanyAvatar': 'Ảnh đại diện phải là định dang {{files}}',
  'message.validateTypeFileCompanyLogo': 'Logo phải là định dạng',
  'message.validateTypeFileJobTypeLogo': 'Logo phải là định dạng',
  'message.validateTypeFileContractService': 'Hợp đồng phải là dạng tệp {{file}}',
  'message.pleaseUploadServiceContract': 'Tải lên hợp đồng dịch vụ',
  'message.validateTypeFileCompanyBanner': 'Banner phải là dạng tệp {{file}}',
  'message.registrationCompleted': 'Đăng ký đã hoàn thành.',
  'message.changeCompleted': 'Thay đổi đã hoàn thành.',
  'message.wantToSubmit': 'Xác nhận ngay?',
  'message.wantToSave': 'Lưu ngay?',
  'message.wantToContinue': 'Tiếp tục ngay?',
  'message.pleaseSelectCompany': 'Chọn đối tác',
  'message.selectCompany': 'Chọn đối tác',
  'message.pleaseEnterCompanyName': 'Nhập tên đối tác',
  'message.pleaseEnterCompanyAddress': 'Nhập địa chỉ đối tác',
  'message.pleaseEnterBusinessCompany': 'Nhập mã số kinh doanh',
  'message.pleaseEnterMinJODCreditLimit': 'Nhập hạn mức xu Jod',
  'message.enableJobType': 'Bạn đã chọn kích hoạt loại công việc này.',
  'message.disableJobType': 'Bạn đã chọn vô hiệu loại công việc này.',
  'message.pleaseEnterJobTitle': 'Nhập chức vụ',
  'message.pleaseEnterJobDescription': 'Nhập mô tả công việc',
  'message.pleaseEnterComment': 'Nhập bình luận',
  'message.commentLengthCannotExceed255Characters': 'Độ dài bình luận không được vượt quá 255 ký tự',
  'message.minAvailablePositionsLargerThanOrEqualTo0': 'Số việc tối thiểu phải lớn hơn hoặc bằng 0',
  'message.maxAvailablePositionsLargerThanOrEqualTo0': 'Số việc tối đa phải lớn hơn hoặc bằng 0',
  'message.minAvailableCompaniesLargerThanOrEqualTo0':
    'Số đối tác tối thiểu phải lớn hơn hoặc bằng 0',
  'message.maxAvailableCompaniesLargerThanOrEqualTo0': 'Số đối tác tối đa phải lớn hơn hoặc bằng 0',
  'message.minLessThanMaxAvailablePositions': 'Số việc tối thiểu phải nhỏ hơn số việc tối đa',
  'message.maxGreaterThanMinAvailablePositions': 'Số việc tối đa phải lớn hơn số việc tối thiểu',
  'message.minLessThanMaxAvailableCompanies': 'Số đối tác tối thiểu phải nhỏ hơn số đối tác tối đa',
  'message.maxGreaterThanMinAvailableCompanies':
    'Số đối tác tối đa phải lớn hơn số đối tác tối thiểu',
  'message.pleaseEnterFirstName': 'Nhập tên',
  'message.pleaseEnterLastName': 'Nhập họ',
  'message.pleaseEnterWorkEmail': 'Nhập email công việc',
  'message.workEmailMustBeValidEmailAddress': 'Email công việc phải được xác nhận',
  'message.firstNameHasMaximum100Characters': 'Tên có tối đa 100 ký tự',
  'message.firstNameHasMinimum1Character': 'Tên có tối thiểu 1 ký tự.',
  'message.firstNameAlphabetsOnlyNoNumericNoSpecialCharacters': `Tên phải thuộc bảng chữ cái, không bao gồm chữ số, ký tự đặc biệt (ngoại trừ ,-/'@).`,
  'message.lastNameHasMaximum100Characters': 'Họ có tối đa 100 ký tự',
  'message.lastNameHasMinimum1Character': 'Họ có tối thiểu 1 ký tự',
  'message.lastNameAlphabetsOnlyNoNumericNoSpecialCharacters': `Họ phải thuộc bảng chữ cái, không bao gồm chữ số, ký tự đặc biệt (ngoại trừ ,-/'@).`,
  'message.enableLocation': 'Bạn đã chọn kích hoạt chi nhánh này.',
  'message.disableLocation': 'Bạn đã chọn vô hiệu chi nhánh này.',
  'message.noData': 'Không có dữ liệu',
  'message.locationNotFound': 'Không tìm thấy kết quả',
  'message.onlyAllowTwoDecimalPlace': 'Cho phép tối đa 2 số thập phân',
  'message.commentRequired': 'Nhập bình luận',
  'message.creditAmountRequired': 'Nhập số xu Jod',
  'message.locationLogoMustBe': 'Logo phải là định dạng',
  'message.locationNameRequired': 'Nhập tên chi nhánh',
  'message.locationAddressRequired': 'Nhập địa chỉ chi nhánh',
  'message.minJODCreditRequired': 'Nhập giới hạn xu Jod tối thiểu',
  'message.firstNameRequired': 'Nhập tên',
  'message.lastNameRequired': 'Nhập họ',
  'message.jobTitleRequired': 'Nhập chức vụ',
  'message.workEmailRequired': 'Nhập email công việc',
  'message.enableManager': 'Bạn đã chọn kích hoạt quản lý này.',
  'message.disableManager': 'Bạn đã chọn vô hiệu quản lý này.',
  'message.managerTypeRequired': 'Chọn loại hình quản lý',
  'message.pleaseEnterApplicantFirstName': 'Nhập tên ứng viên',
  'message.pleaseEnterApplicantLastName': 'Nhập họ ứng viên',
  'message.pleaseEnterReasonHere': 'Nhập lý do',
  'message.enableAccountBelowPreviousReasonOfDisabling':
    'Vui lòng xem lại lý do khiến tài khoản bị vô hiệu trước khi kích hoạt lại.',
  'message.disableAccountPleaseClarifyYourReason':
    'Vui lòng cung cấp lý do trước khi vô hiệu tài khoản.',
  'message.choseMarkApplicantIdentityAsValid': 'Thông tin ứng viên hợp lệ',
  'message.choseMarkApplicantIdentityAsInvalid': 'Thông tin ứng viên không hợp lệ',
  'message.displayNameRequired': 'Nhập tên hiển thị',
  'message.displayNameMax100Char': 'Tên hiển thị có tối đa 100 ký tự',
  'message.displayNameMin2Char': 'Tên hiển thị có tối thiểu 2 ký tự',
  'message.displayNameMustBeUserName': `Tên hiển thị phải thuộc bảng chữ cái, không bao gồm chữ số, ký tự đặc biệt (ngoại trừ ,-/'@).`,
  'message.pleaseEnterApplicantDateOfBirth': 'Nhập ngày sinh ứng viên',
  'message.ageBetween': 'Tuổi ứng viên phải từ {{from}} đến {{to}}.',
  'message.pleaseEnterGenderInOptionsAbove': 'Nhập giới tính',
  'message.formatInvalidInNRIC/FIN': 'CCCD/CMND yêu cầu từ 9 hoặc 12 ký tự.',
  'message.NRIC/FINrequired': 'Nhập số CCCD/CMND',
  'message.finDocumentRequired': '',
  'message.pleaseEnterApplicantContactNumber': 'Nhập số điện thoại ứng viên',
  'message.emailMustBeValidEmailAddress': 'Email không hợp lệ',
  'message.pleaseEnterApplicantEmail': 'Nhập email ứng viên',
  'message.validateTypeFileApplicantFoodHygieneCert':
    'Bằng vệ sinh an toàn thực phẩm phải là dạng tệp {{file}}',
  'message.pleaseUploadFoodHygieneCert': 'Tải lên bằng an toàn vệ sinh thực phẩm',
  'message.minimumRatingLargerThanOrEqualTo0': 'Xếp hang tối thiểu phải lớn hơn hoặc bằng 0',
  'message.maximumRatingLargerThanOrEqualTo0': 'Xếp hang tối đa phải lớn hơn hoặc bằng 0',
  'message.amountJobGreaterThanOrEqualTo0': 'Số việc phải lớn hơn hoặc bằng 0',
  'message.minimumRatingLessThan5': 'Xếp hang tối thiểu phải nhỏ hơn 5',
  'message.maximumRatingLessThan5': 'Xếp hang tối đa phải lớn hơn hoặc bằng 5',
  'message.minimumRatingLessThanMaximumRating': 'Xếp hang tối thiểu phải nhỏ hơn xếp hạng tối đa',
  'message.maximumRatingGreaterThanMinimumRating':
    'Xếp hang tối đa phải lớn hơn xếp hạng tối thiểu',
  'message.choseDisableInstitute': 'Bạn đã chọn vô hiệu trường này.',
  'message.choseEnableInstitute': 'Bạn đã chọn kích hoạt trường này.',
  'message.pleaseEnterInstituteName': 'Nhập tên trường',
  'message.pleaseEnterInstituteDescription': 'Nhập mô tả trường',
  'message.pleaseEnterInstituteAddress': 'Nhập địa chỉ trường',
  'message.enableJobTemplate': 'Bạn đã chọn kích hoạt mẫu việc này.',
  'message.disableJobTemplate': 'Bạn đã chọn vô hiệu mẫu việc này.',
  'message.templateNameRequired': 'Nhập việc mẫu',
  'message.templateNameMax100Char': 'Tên mẫu tối đa 100 ký tự',
  'message.templateNameMin2Char': 'Tên mẫu tối thiểu 2 ký tự',
  'message.jobTitleMax100Char': 'Chức vụ tối đa 100 ký tự',
  'message.jobTitleMin2Char': 'Chức vụ tối thiểu 2 ký tự',
  'message.jobTypeRequired': 'Nhập danh mục việc',
  'message.jobDescriptionRequired': 'Nhập mô tả công việc',
  'message.jobStartDateRequired': 'Chọn ngày bắt đầu',
  'message.jobStartDateBeforeJobEndDate': 'Ngày bắt đầu phải trước ngày kết thúc',
  'message.jobEndDateRequired': 'Chọn ngày kết thúc',
  'message.jobEndDateAfterJobStartDate': 'Ngày kết thúc phải sau ngày bắt đầu',
  'message.hourlyRateGreaterThanZero': 'Lương theo giờ phải lớn hơn 0',
  'message.dateMustBeBeforeOrEqualCurrentDateTime': 'Ngày phải trước hoặc bằng ngày hiện tại',
  'message.pleaseEnterAssigningAmount': 'Nhập số lượng cấp hợp lệ',
  'message.confirmAssignCredits': 'Xác nhận cấp xu Jod?',
  'message.minimumAssignAmountLessThanMaximumAssignAmount':
    'Số xu Jod tối thiểu (được cấp) phải nhỏ hơn số xu Jod tối đa (được cấp)',
  'message.maximumAssignAmountGreaterThanMinimumAssignAmount':
    'Số xu Jod tối đa (được cấp) phải lớn hơn số xu Jod tối thiểu (được cấp)',
  'message.selectedPayment': 'Bạn đã chọn <0 ><1> {{countSelected}} </1></0> thanh toán',
  'message.selectedMultiplePayment': 'Bạn đã chọn <0 ><1> {{countSelected}} </1></0> thanh toán',
  'message.wantToProcessThesePayments': 'Tiếp tục xử lý thanh toán?',
  'message.applicantMustCompleteFeedbackBeforePaymentIsProcessed':
    'Ứng viên phải hoàn thành đánh giá để xử lý thanh toán',
  'message.applicantJobDetailsOnlyShownWhenClockOutDone':
    'Chi tiết công việc chỉ hiện sau khi ứng viên hoàn thành chấm công ra',
  'message.jobNotFound': 'Không tìm thấy công việc',
  'message.pleaseSelectClockInTime': 'Chọn giờ chấm công vào',
  'message.pleaseSelectClockOutTime': 'Chọn giờ chấm công ra',
  'message.pleaseSelectBreakTime': 'Chọn giờ nghỉ',
  'message.pleaseEnterWagePerHour': 'Nhập lương theo giờ',
  'message.pleaseSelectBank': 'Chọn ngân hàng',
  'message.wagePerHourMustBeLargerThan0': 'Lương theo giờ phải lớn hơn 0',
  'message.clockInTimeMustBeBeforeClockOutTime': 'Giờ chấm công ra phải trước giờ chấm công vào',
  'message.clockOutTimeMustBeAfterClockInTime': 'Giờ chấm công vào phải sau giờ chấm công ra',
  'message.breakTimeLessThanTotalWorkingHours': 'Số giờ nghỉ phải nhỏ hơn số giờ làm.',
  'message.pleaseEnterAllowance': 'Nhập phụ cấp',
  'message.jodAllowanceLargerThan0': 'Trợ cấp Jod phải lớn hơn 0',
  'message.bankHolderNameLessThanOrEqualTo35Char': 'Tên chủ tài khoản ngân hàng có tối đa 35 ký tự',
  'message.bankAccountNumberMustBeBetween8to15Digits': 'Số tài khoản ngân hàng từ 8 đến 15 chữ số',
  'message.bankAccountNumberMustBeAtLeast': 'Số tài khoản ngân hàng tối thiểu {{count}} chữ số',
  'message.bankAccountNumberMustBeBetween9to12Digits': 'Số tài khoản ngân hàng từ 9 đến 12 chữ số',
  'message.min12To16DigitsNoDashOtherSpecialChar':
    'Có từ 12 đến 16 chữ số, không gạch nối hoặc ký tự đặc biệt',
  'message.min9To12DigitsNoDashOtherSpecialChar':
    'Có từ 9 đến 12 chữ số, không gạch nối hoặc ký tự đặc biệt.',
  'message.minCountDigitsNoDashOtherSpecialChar':
    'Có từ {{count}} chữ số, không gạch nối hoặc ký tự đặc biệt.',
  'message.min8To15DigitsNoDashOtherSpecialChar':
    'Có từ 8 đến 15 chữ số, không gạch nối hoặc ký tự đặc biệt',
  'message.bankHolderNameFormatIsInvalid': 'Định dạng tên chủ tài khoản ngân hàng không hợp lệ',
  'message.bankAccountNumberFormatIsInvalid': 'Định dạng số tài khoản ngân hàng không hợp lệ',
  'message.limitTo35CharAlphaOnlyNoDashOtherSpecialChar':
    'Tối đa 35 ký tự, bao gồm alpha, không gạch nối hoặc ký tự đặc biệt',
  'message.wantToProcessThisPayment': 'Tiếp tục xử lý thanh toán?',
  'message.wantToDeleteJob': 'Xoá công việc này?',
  'message.wantToRejectJob': 'Từ chối công việc này?',
  'message.wantToApproveJob': 'Phê duyệt công việc này?',
  'message.pleaseScanQrCode': 'Quét mã QR',
  'message.cancelApplicant': 'Huỷ ứng viên này?',
  'message.clockInBeforeClockOut': 'Giờ chấm công ra phải trước giờ chấm công vào',
  'message.clockOutAfterClockIn': 'Giờ chấm công vào phải sau giờ chấm công ra',
  'message.ratingRequired': 'Chọn xếp hạng',
  'message.workingHoursMoreThan18Hours': 'Thời gian làm không quá 18 giờ',
  'message.deletedAccount': 'Tài khoản đã xoá',
  'message.haveSelected': 'Bạn đã chọn',
  'message.wantToProcess': 'Tiếp tục xử lý?',
  'message.templateNameOptionRequired': 'Chọn việc mẫu',
  'message.locationRequired': 'Chọn chi nhánh',
  'message.dateRangeRequired': 'Chọn thời gian làm',
  'message.jobStartDateAfterCurrent': 'Thời gian bắt đầu phải sau thời gian hiện tại',
  'message.jobEndDateAfterCurrent': 'Thời gian kết thúc phải sau thời gian hiện tại',
  'message.jobStartBeforeJobEnd': 'Thời gian bắt đầu phải trước thời gian kết thúc',
  'message.earningPerHourRequired': 'Nhập lương theo giờ',
  'message.earningPerHourLargerThan0': 'Lương theo giờ phải lớn hơn 0',
  'message.atLeastOneWorkingDay': 'Chọn ít nhất một ngày làm',
  'message.postingNewJob': 'Bạn vừa đăng tuyển.',
  'message.wantToChangeValueOfSelectedCountOtherConfiguration':
    'Thay đổi {{selectedCount}} giá trị cấu hình?',
  'message.wantToChangeValueOfSelectedCountMultipleOtherConfiguration':
    'Thay đổi {{selectedCount}} giá trị cấu hình?',
  'message.wantToChangeValueOfBadgeConfiguration': 'Thay đổi giá trị cấu hình <0 /> {{label}}?',
  'message.wantToChangeStatusOfEmailNotification':
    'Bạn có muốn thay đổi <0/> tình trạng thông báo Email {{selectedCount}}?',
  'message.wantToChangeStatusPushNotification':
    'Bạn có muốn thay đổi <0/> tình trạng thông báo ứng dụng {{selectedCount}}?',
  'message.wantToChangeStatusSMSNotification':
    'Bạn có muốn thay đổi <0/> tình trạng thông báo SMS {{selectedCount}}?',
  'message.validateTypeFileLocationLogo': 'Tên logo phải là định dạng',
  'message.pleaseEnterPassword': 'Nhập mật khẩu',
  'message.pleaseEnterNewPassword': 'Nhập mật khẩu mới',
  'message.pleaseEnterConfirmPassword': 'Nhập lại mật khẩu mới',
  'message.newPasswordMinimum6Char': 'Mật khẩu mới phải có tối thiểu 6 ký tự',
  'message.confirmPasswordMinimum6Char': 'Mật khẩu phải có tối thiểu 6 ký tự',
  'message.passwordMinimum6Char': 'Mật khẩu phải có tối thiểu 6 ký tự.',
  'message.choseDisableRole': 'Bạn đã chọn vô hiệu vai trò này.',
  'message.choseEnableRole': 'Bạn đã chọn kích hoạt vai trò này.',
  'message.choseDisableUser': 'Bạn đã chọn vô hiệu người dùng này.',
  'message.choseEnableUser': 'Bạn đã chọn kích hoạt người dùng này.',
  'message.pleaseSelectRole': 'Chọn vai trò',
  'message.pleaseEnterRoleName': 'Nhập tên vai trò',
  'message.pleaseEnterDescription': 'Nhập mô tả vai trò',
  'message.descriptionMustBeGreaterThan255Char': 'Mô tả có tối đa 255 ký tự',
  'message.minimumWageLargerOrEqual0': 'Lương tối thiểu phải lớn hơn hoặc bằng 0',
  'message.minimumWageLessThanMaximumWage': 'Lương tối thiểu phải nhỏ hơn lương tối đa',
  'message.maximumWageGreaterThanMinimumWage': 'Lương tối đa phải lớn hơn lương tối thiểu',
  'message.maximumWageLargerOrEqual0': 'Lương tối đa phải lớn hơn hoặc bằng 0',
  'message.wantToUpdateWagePerHourForAJob':
    'Xác nhận thay đổi: <0/> lương theo giờ từ {{currentWage}} đến {{newWage}} cho {{jobCount}} việc.',
  'message.wantToUpdateWagePerHourForMultiJob':
    'Xác nhận thay đổi: <0/> lương theo giờ từ {{currentWage}} đến {{newWage}} cho {{jobCount}} việc.',
  'message.currentWageRequired': 'Nhập lương theo giờ hiện tại',
  'message.currentWageLargerThan0': 'Lương theo giờ phải lớn hơn hoặc bằng 0',
  'message.newWageRequired': 'Nhập lương theo giờ mới',
  'message.newWageLargerThan0': 'Lương theo giờ phải lớn hơn hoặc bằng 0',
  'message.differentValidValueForCurrentAndNewWage':
    'Nhập giá trị khác nhau cho lương theo giờ hiện tại và lương theo giờ mới',
  'message.selectedAJob': 'Bạn đã chọn <0><1> {{selectedCount}} </1></0> việc',
  'message.selectedMultipleJob': 'Bạn đã chọn <0><1> {{selectedCount}} </1></0> việc',
  'message.wantToCancelAJob': 'Tiếp tục huỷ việc?',
  'message.wantToCancelMultipleJob': 'Tiếp tục huỷ việc?',
  'message.totalNumberOfAvailableCredits': 'Số xu Jod <0/>hiện có',
  'message.consumedCreditCurrentMonth': 'Số xu Jod đã dùng <0/> (tháng này)',
  'message.jodCreditsAvailableUnderAreaManager': 'Số xu Jod hiện có <0/>tại vùng',
  'message.jodCreditsAvailableUnderLocationManager': 'Số xu Jod hiện có <0/>tại chi nhánh',
  'message.minimumTotalJodCreditLessThanMaximumTotalJodCredit':
    'Số xu Jod tối thiểu phải nhỏ hơn số xu Jod tối đa',
  'message.maximumTotalJodCreditGreaterThanMinimumTotalJodCredit':
    'Số xu Jod tối đa phải lớn hơn số xu Jod tối thiểu',
  'message.wantToChangeTheLogo': 'Bạn có muốn thay đổi logo?',
  'message.totalNumberOfPartnerCompanies': 'Số đối tác <0 />',
  'message.totalNumberOfHQManagers': 'Số giám đốc khối <0 />',
  'message.totalNumberOfFuturePayments': 'Số thanh toán mới <0 />',
  'message.minimumLessThanMaximumAvailableConsumed':
    'Số đã dùng tối thiểu phải nhỏ hơn số đã dùng tối đa',
  'message.maximumGreaterThanMinimumAvailableConsumed':
    'Số đã dùng tối đa phải lớn hơn số đã dùng tối thiểu',
  'message.totalNumberOfJobs': 'Số việc <0 />',
  'message.totalNumberOfRejectCandidates': 'Số Ứng viên bị từ chối <0 />',
  'message.createdApplicantUnsuccess': 'Tạo chưa thành công',
  'message.updatedApplicantUnsuccess': 'Cập nhật chưa thành công',
  'message.enableApplicantUnsuccess': 'Kích hoạt chưa thành công.',
  'message.disableApplicantUnsuccess': 'Vô hiệu chưa thành công',
  'message.updateApplicantIdentityStatusUnsuccess': 'Cập nhập định danh ứng viên chưa thành công',
  'message.downloadSuccess': 'Tải xuống thành công',
  'message.createdAreaManagerUnsuccess': 'Tạo chưa thành công',
  'message.updatedAreaManagerUnsuccess': 'Cập nhật chưa thành công',
  'message.disableAreaManager': 'Vô hiệu chưa thành công',
  'message.enableAreaManager': 'Kích hoạt chưa thành công.',
  'message.createdCompanyUnsuccess': 'Tạo chưa thành công',
  'message.updatedCompanyUnsuccess': 'Cập nhật chưa thành công',
  'message.disableCompanyUnsuccess': 'Vô hiệu chưa thành công',
  'message.enableCompanyUnsuccess': 'Kích hoạt chưa thành công.',
  'message.bannerCompanyHasNotBeenUploaded': 'Banner đối tác chưa được tải!',
  'message.uploadBannerCompanyUnsuccess': 'Tải chưa thành công',
  'message.exportSuccess': 'Xuất tệp thành công',
  'message.changeStatusNotificationUnsuccess': 'Thay tình trạng thông báo chưa thành công',
  'message.changeValueOtherConfigurationUnsuccess': 'Thay đổi giá trị cấu hình chưa thành công',
  'message.assignCreditUnsuccess': 'Cấp xu Jod chưa thành công',
  'message.haveNoPermissionAccessFeature': 'Chưa được cấp quyền sử dụng tính năng',
  'message.userAdminLogoFileHasNotBeenUploadYet': 'Logo chưa được đăng tải',
  'message.updateFailed': 'Cập nhật chưa thành công',
  'message.getAdminLogoFailed': 'Tải logo chưa thành công',
  'message.disableEducationalInstitutesUnsuccess': 'Vô hiệu chưa thành công',
  'message.enableEducationalInstitutesUnsuccess': 'Kích hoạt chưa thành công.',
  'message.createdHQManagerUnsuccess': 'Tạo chưa thành công',
  'message.updatedHQManagerUnsuccess': 'Cập nhật chưa thành công',
  'message.disableHQUserUnsuccess': 'Vô hiệu chưa thành công',
  'message.enableHQUserUnsuccess': 'Vô hiệu chưa thành công',
  'message.createdUserUnsuccess': 'Tạo chưa thành công',
  'message.updatedUserUnsuccess': 'Cập nhật chưa thành công',
  'message.disableUserUnsuccess': 'Vô hiệu chưa thành công',
  'message.enableUserUnsuccess': 'Kích hoạt chưa thành công.',
  'message.disableJobTemplateUnsuccess': 'Vô hiệu chưa thành công',
  'message.enableJobTemplateUnsuccess': 'Kích hoạt chưa thành công.',
  'message.createdJobTemplateUnsuccess': 'Tạo chưa thành công',
  'message.updatedJobTemplateUnsuccess': 'Cập nhật chưa thành công',
  'message.createdJobTypeUnsuccess': 'Tạo chưa thành công',
  'message.updatedJobTypeUnsuccess': 'Cập nhật chưa thành công',
  'message.disableJobTypeUnsuccess': 'Vô hiệu chưa thành công',
  'message.enableJobTypeUnsuccess': 'Kích hoạt chưa thành công.',
  'message.createdNewJobUnsuccess': 'Tạo chưa thành công',
  'message.updatedNewJobUnsuccess': 'Cập nhật chưa thành công',
  'message.deletedJobFailed': 'Xoá chưa thành công',
  'message.approveJobFailed': 'Duyệt chưa thành công',
  'message.cancelApplicantFailed': 'Huỷ chưa thành công',
  'message.cancelUnsuccess': 'Huỷ chưa thành công',
  'message.createdLocationManagerUnsuccess': 'Tạo chưa thành công',
  'message.updatedLocationManagerUnsuccess': 'Cập nhật chưa thành công',
  'message.createdLocationUnsuccess': 'Tạo chưa thành công',
  'message.updatedLocationUnsuccess': 'Cập nhật chưa thành công',
  'message.disableLocationUnsuccess': 'Vô hiệu chưa thành công',
  'message.enableLocationUnsuccess': 'Kích hoạt chưa thành công.',
  'message.confirmUnsuccess': 'Chưa thành công',
  'message.downloadApplicantJobSummaryUnsuccess': 'Tải chưa thành công',
  'message.createdRoleUnsuccess': 'Tạo chưa thành công',
  'message.updatedRoleUnsuccess': 'Cập nhật chưa thành công',
  'message.disableRoleUnsuccess': 'Vô hiệu chưa thành công',
  'message.enableRoleUnsuccess': 'Kích hoạt chưa thành công.',
  'message.passwordChangeFailed': 'Đổi mật khẩu chưa thành công',
  'message.youNoPermissionAccessPage': 'Bạn cần được cấp quyền truy cập',
  'message.hourlyRate': 'Nhập lương theo giờ',
  'message.tableSummary': 'Hiển thị giá trị từ {{from}} đến {{to}}',
  'message.invalidPasswordResetLink':
    'URL đặt lại mật khẩu của bạn không còn hiệu lực. Vui lòng thử lại.',
  'message.duplicatedEmailError': 'Email đã được dùng',
  'message.valueMustBeAlphabeticOnly': 'Giá trị phải là chữ cái.',
  'message.candidate': 'ứng viên',
  'message.candidates': 'ứng viên',
  'message.valueMustBeNumericOnly': 'Giá trị phải là chữ số.',
  'message.valueMustBeAlphaNumeric': 'Giá trị phải là chữ hoặc số.',
  'message.pleaseSelectAverageRating': 'Chọn bậc xếp hạng trung bình',
  'message.commonRequired': 'Không được bỏ trống',
  'message.locationManagerIsNull': 'You are posting a new job without selecting a Location Manager. The location manager will not be able to see this job post. Please select a Location Manager to avoid this.',

  'value.all': 'Tất cả',
  'value.enable': 'Kích hoạt',
  'value.disable': 'Vô hiệu',
  'value.include': 'Bao gồm',
  'value.exclude': 'Chưa gồm',
  'value.male': 'Nam',
  'value.female': 'Nữ',
  'value.other': 'Khác',
  'value.recentAddedLocations': 'Chi nhánh tạo gần đây',
  'value.enableLocations': 'Kích hoạt',
  'value.disableLocations': 'Vô hiệu',
  'value.location': 'Chi nhánh',
  'value.area': 'Vùng',
  'value.locationManager': 'Quản lý chi nhánh',
  'value.areaManager': 'Quản lý vùng',
  'value.recentAddedManagers': 'Quản lý tạo gần đây',
  'value.disableManagers': 'Vô hiệu',
  'value.enableManagers': 'Kích hoạt',
  'value.jobWithApplicants': 'Việc có ứng viên',
  'value.recentAddedJobTemplates': 'Việc mẫu tạo gần đây',
  'value.enableJobTemplates': 'Kích hoạt',
  'value.disableJobTemplates': 'Vô hiệu',
  'value.yearOld': '{{age}} tuổi',
  'value.inThisSlot': 'Chỉ trong ca này',
  'value.inAllSlot': 'Trong tất cả ca chưa hoàn thành',
  'value.singleDayJobs': 'Việc một ngày',
  'value.multiDayJobs': 'Việc nhiều ngày',
  'value.pending': 'Đợi duyệt',
  'value.open': 'Mới',
  'value.active': 'Đang làm',
  'value.completed': 'Hoàn thành',
  'value.cancelled': 'Huỷ',
  'value.expiredWithNoApplicants': 'Hết hạn - Không có ứng viên',
  'value.expiredNoSelection': 'Hết hạn - Không có lựa chọn',
  'value.activeButNotClockIn': 'Đang chờ',
  'value.jan': 'Tháng 1',
  'value.feb': 'Tháng 2',
  'value.mar': 'Tháng 3',
  'value.apr': 'Tháng 4',
  'value.may': 'Tháng 5',
  'value.jun': 'Tháng 6',
  'value.jul': 'Tháng 7',
  'value.aug': 'Tháng 8',
  'value.sep': 'Tháng 9',
  'value.oct': 'Tháng 10',
  'value.nov': 'Tháng 11',
  'value.dec': 'Tháng 12',
  'value.unprocessed': 'Chưa xử lý',
  'value.processed': 'Đã xử lý',
  'value.inProgress': 'Đang xử lý',
  'value.perPage': '/ trang',
  'value.hired': 'Đã tuyển',
  'value.rejected': 'Quản lý từ chối',
  'value.rejectedPartially': 'Chấp nhận Một Phần bởi Quản lý',
  'value.selfCancelled': 'Ứng viên huỷ',
  'value.ack1': 'Xác nhận 1',
  'value.ack2': 'Xác nhận 2',
  'value.clockedIn': 'Chấm công vào',
  'value.clockedOut': 'Chấm công ra',
  'value.adminDisable': 'Từ chối do bị Admin vô hiệu',
  'value.adminCompanyDisable': 'Vô hiệu hóa bởi Quản trị viên Siêu (Công ty)',
  'value.adminOutletDisable': 'Vô hiệu hóa bởi Quản trị viên Siêu (Điểm bán)',
  'value.adminApplicantDisable': 'Vô hiệu hóa bởi Quản trị viên Siêu (Người nộp đơn)',
  'value.noShow': 'Vắng mặt',
  'value.noOptions': 'Không có lựa chọn',
  'value.applied': 'Đã ứng tuyển',
  'value.selected': 'Đã chọn',
  'value.unselected': 'Chưa chọn',
  'value.preSelectSelfCancelled': 'Hủy (Trước Lựa Chọn)',
  'value.timeOverlapReject': 'Huỷ do trùng giờ',
  'value.postSelectSelfCanceled': 'Hủy (Sau Lựa Chọn)',
  'value.unknown': 'Không xác định',
  'value.su': 'CN',
  'value.mo': 'T2',
  'value.tu': 'T3',
  'value.we': 'T4',
  'value.th': 'T5',
  'value.fr': 'T6',
  'value.sa': 'T7',
  'value.unverifiedIdentity': 'Chưa xác minh',
  'value.validIdentity': 'Đã xác minh',
  'value.invalidIdentity': 'Không hợp lệ',
  'value.unavailableIdentity': 'Không khả dụng',
  'value.completedWithoutAppliedApplicant': 'Hoàn thành không có ứng viên',
  'value.completedWithoutHiringManager': 'Hoàn thành không có quản lý',
  'value.noHiring': 'Hết hạn mà không tuyển dụng',
  'value.noApplicants': 'Vì không có ứng viên, không có gì để hiển thị',
};
