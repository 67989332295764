import { FC } from 'react';
import { Image, ImageProps } from '@chakra-ui/react';

import logo from 'assets/images/Logo.png';
import logoX2 from 'assets/images/Logo@2x.png';

interface Props extends Omit<ImageProps, 'src'> {
  x2?: boolean;
}

export const Logo: FC<Props> = (props) => {
  return <Image marginTop={'5px'} src={props.x2 ? logoX2 : logo} {...props} />;
};
