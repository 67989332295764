import pick from 'lodash/pick';

import { RootState } from 'store/root-reducer';

export const headerTitleSelector = (state: RootState) =>
  pick(state.config, 'headerTitle', 'headerTitleBreakLine');
export const deviceSelector = (state: RootState) => state.config.device;
export const screenSelector = (state: RootState) => state.config.screen;
export const counterFlagSelector = (state: RootState) => state.config.counterFlag;
export const jodAdminSelector = (state: RootState) => state.config.jodAdmin;
